export const nativeShare = async ({ title, text /*files = null*/ }) => {
  try {
    await window.navigator.share({
      title: title,
      text: text,
      // files: files ? [files] : undefined
    });
  } catch (error) {
    console.log(error);
    alert("Erro ao compartilhar, sem suporte para o navegador");
  }
};

export const shareToSocialMedia = (platform, value) => {
  let url = "";
  switch (platform) {
    case "facebook":
      url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        value
      )}`;
      break;
    case "whatsapp":
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        url = `https://api.whatsapp.com/send?text=${encodeURIComponent(value)}`;
      } else {
        url = `https://web.whatsapp.com/send?text=${encodeURIComponent(value)}`;
      }
      break;
    default:
      console.error("Plataforma de compartilhamento nÃ£o suportada");
      return;
  }

  // Abre uma nova janela do navegador com a URL gerada para compartilhamento
  window.open(url, "_blank");
};
