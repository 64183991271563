<template>
  <div class="dash-widget full-height full-width">
    <span class="widget-separator" />
    <div class="width-fit-content-flex dash-widget" :class="classes">
      <div class="widget-header">
        <div
          class="title-widget text-ds-grey text-uppercase text-weight-medium q-mt-none"
        >
          <div class="row items-center" style="margin-top: -10px">
            <div :class="$q.screen.lt.md ? 'col-10 col-md-6' : ''">
              {{ title }}
            </div>
            <div :class="$q.screen.lt.md ? 'col-2' : ''">
              <tooltip :tooltip_message="tooltip_message" size="0.8rem" />
            </div>
          </div>

          <div class="title-more-info" v-if="sub_label">
            <span class="text-capitalize text-ds-grey">{{ sub_label }}</span>
          </div>
        </div>
      </div>
      <div class="row widget-item-inner">
        <div
          class="q-align-center q-mt-sm"
          :class="{
            'col-3':
              ((length == 4 || length == 5) && $q.screen.width > 900) ||
              $q.screen.width < 900,
            'col-2': length == 3 && $q.screen.width > 900,
            'col-1': length < 3 && $q.screen.width > 900,
          }"
        >
          <q-img :src="require(`/public/dash-icons/${icon}`)" class="img" />
        </div>

        <div
          :class="{
            'col-9':
              ((length == 4 || length == 5) && $q.screen.width > 900) ||
              $q.screen.width < 900,
            'col-10': length == 3 && $q.screen.width > 900,
            'col-11': length < 3 && $q.screen.width > 900,
          }"
        >
          <div
            class="widget-content q-mt-sm column items-start"
            :style="$q.screen.lt.md ? 'margin-left: 14px' : 'margin-left: 18px'"
          >
            <Vue3autocounter
              v-if="type === 'money'"
              class="text-counter-size"
              ref="counter"
              :startAmount="0"
              :endAmount="value"
              :duration="1"
              :prefix="$t('global.moneyTypeLabel') + ' '"
              separator="."
              decimalSeparator=","
              :decimals="2"
              :autoinit="true"
            />
            <Vue3autocounter
              v-if="type === 'number'"
              class="text-counter-size"
              ref="counter"
              :startAmount="0"
              :endAmount="value"
              :duration="1"
              separator="."
              decimalSeparator=","
              :decimals="2"
              :autoinit="true"
            />

            <div
              v-if="
                credits_under_analysis ||
                identifier === 'points_expired_or_expires'
              "
              class="q-py-none q-mt-sm aligm-center text-small-info section-credits-under-analysis"
              :style="{ color: colorFeeling[feeling] }"
            >
              <span style="line-height: 1.2">
                <span
                  v-text="
                    toMoney(
                      credits_under_analysis || reward_points_expired_soon
                    )
                  "
                />
                <span
                  v-text="
                    identifier === 'points_expired_or_expires'
                      ? ' créditos à expirar'
                      : ' créditos em análise'
                  "
                />
                <tooltip
                  :tooltip_message="
                    identifier === 'points_expired_or_expires'
                      ? $t(
                          'dashboard.tooltip_message.reward_points_expired_soon'
                        )
                      : $t('dashboard.credits_analysis')
                  "
                  size="0.5rem"
                />
              </span>
            </div>

            <div
              v-if="link === 'sales'"
              class="text-small-info q-mt-sm text-left"
              :style="{ color: colorFeeling[feeling] }"
            >
              <span
                class="text-center"
                v-if="total_orders === 0"
                v-text="$t('dashboard.no_orders_in_the_period')"
              />
              <span v-else>
                <strong>{{ total_orders }}</strong>
                <span v-text="total_orders > 1 ? ' pedidos' : ' pedido'" />
              </span>
            </div>

            <div
              class="text-small-info q-mt-sm"
              v-if="
                link !== 'sales' &&
                !credits_under_analysis &&
                identifier !== 'points_expired_or_expires'
              "
              :style="{ color: colorFeeling[feeling] }"
            >
              <q-icon name="expand_less" />
              {{ type === "money" ? toMoneyLabel(details.past) : details.past }}
              ( {{ toPercent(details.percent) }}% )
            </div>
            <rickshaw-chart :color="colorFeeling[feeling]" :series="series" />
          </div>
        </div>
        <div
          class="container-btn-action"
          :class="link == 'sales' ? 'grid' : 'row justify-center'"
        >
          <q-btn
            v-if="showRescueButton(button_title, value) && button_title"
            :href="link"
            color="ds-grey"
            outline
            size="sm"
            class="q-mt-xs btn-action"
            :label="$t(button_title)"
            :icon="button_icon"
          />
          <span
            v-if="!showRescueButton(button_title, value)"
            class="text-center text-ds-grey"
          >
            {{ $t("dashboard.reward_no_points_message") }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import RickshawChart from "@/modules/main/pages/home/components/rickshawChart";
import { toMoney } from "../../../../../shared/helpers/numbersManipulation";
import { toMoneyLabel } from "@/shared/helpers/numbersManipulation";
import tooltip from "../../../components/tooltip.vue";
import Vue3autocounter from "vue3-autocounter";
import { defineProps } from "vue";
import { useQuasar } from "quasar";

defineProps({
  widget: {
    type: Object,
    required: true,
  },
  skeleton: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: "number",
  },
  total_orders: {
    type: Number,
  },
  feeling: {
    type: String,
    default: "Z",
  },
  value: {
    type: Number,
  },
  credits_under_analysis: {
    type: Number,
  },
  details: {
    type: Object,
  },
  title: {
    required: true,
    type: String,
  },
  link: {
    required: false,
    type: String,
  },
  sub_label: {
    required: false,
    type: String,
  },
  tooltip_message: {
    type: String,
  },
  tooltip: {
    type: String,
  },
  series: {
    type: Array,
  },
  icon: {
    type: String,
  },
  identifier: {
    type: String,
    default: null,
  },
  reward_points_expired_soon: {
    type: Number,
    default: null,
  },
  classes: {
    type: Array,
    default: () => [],
  },
  button_title: {
    required: false,
    type: String,
  },
  button_icon: {
    required: false,
    type: String,
  },
  length: {
    type: Number,
    default: 4,
  },
});

const $q = useQuasar();

const toPercent = (v) => {
  let value = parseFloat(parseFloat(v).toFixed(2));
  if (value > 100) {
    return `+100`;
  } else if (value < -100) {
    return `-100+ `;
  } else {
    return value;
  }
};

const showRescueButton = (buttonTitle, value) => {
  if (buttonTitle == "dashboard.reward_rescue_button" && value <= 0) {
    return false;
  } else {
    return true;
  }
};

const colorFeeling = {
  P: "#06d15c",
  N: "#C10015",
  Z: "#8ebce4",
};
</script>
<script>
export default {
  name: "Widget",
};
</script>

<style lang="scss" scoped>
.dash-widget {
  padding: 8px;

  .title-widget {
    font-size: 0.8em;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .text-counter-size {
    font-size: 20px;
    font-weight: 300;
    text-align: left;
  }

  .tooltip-dash {
    margin: -15px 0px;
    padding: 5px;
  }

  .widget-header {
    .q-btn {
      .q-icon {
        justify-content: unset !important;
      }
    }
  }
}

.img {
  margin-left: 20px;
  margin-top: 2px;
  width: 4em;
}

.text-small-info {
  font-size: clamp(10px, 3vw, 14px) !important;
}

.desktop {
  .container-btn-action,
  .btn-action {
    width: fit-content;
  }

  .btn-action {
    width: 100%;
    padding: 4px 16px !important;
  }

  .btn-action .q-btn__content {
    gap: 5px;
  }
}

.mobile {
  .dash-widget {
    padding: 8px;

    .widget-content {
      text-align: center;
    }

    .title-widget {
      font-size: 0.65em;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .text-counter-size {
      font-size: 14px;
    }

    .widget-footer {
      display: flex;
      justify-content: center;
    }

    .tooltip-dash {
      margin: none;
      padding: none;
    }
  }

  .img {
    padding-top: 1px;
    margin-left: 0px;
    width: 2.5rem;
  }
}

.section-credits-under-analysis,
.section-credits-under-analysis .text-counter-size {
  font-size: clamp(10px, 3vw, 14px) !important;
  text-align: left;
}

.btn-action {
  padding: 4px 14px !important;
}

.container-btn-action,
.btn-action {
  width: 100%;
  font-size: clamp(10px, 3vw, 14px);
}

.btn-action .q-btn__content {
  align-items: center;
  gap: 4px;
  display: flex;
  justify-content: center;
}

.btn-action span {
  font-size: clamp(10px, 1vw, 12px) !important;
  text-transform: initial;
}

.widget-points-expired {
  .text-small-info {
    color: #d63031 !important;
  }
}
</style>
