<template>
  <div
    class="my-store-skeleton full-width full-height bg-grey-3 relative-position row items-center"
    :class="{
      'no-wrap justify-around ': !$q.platform.is.mobile,
      'justify-center ': $q.platform.is.mobile,
    }"
  >
    <q-skeleton
      class="absolute-top"
      width="100%"
      height="100%"
      animation="blink"
    />
    <div class="q-ma-lg">
      <q-skeleton class="q-ma-lg" type="QAvatar" size="10em" />
      <q-skeleton type="text" width="100%" />
      <q-skeleton type="text" width="50%" />
    </div>
    <div class="full-width row justify-center">
      <q-skeleton width="100px" height="150px" />
      <q-skeleton class="q-ma-lg" width="100px" height="150px" />
      <q-skeleton width="100px" height="150px" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "myStoreSkeleton",
});
</script>

<style lang="scss">
.mobile {
  .my-store-skeleton {
    zoom: 0.8;
  }
}
</style>
