<template>
  <div
    v-if="in_request"
    class="relative-position q-pa-md full-width bg-grey-3 "
  >
    <div class="skt absolute-top full-width full-height">
      <q-skeleton type="rect" height="100%" width="100%" animation="blink" />
    </div>
    <vue3-highcharts type="chart" :options="fakeChart()" />
  </div>
  <div v-else class=" q-pa-md full-width bg-white">
    <div class="title-widget text-ds-grey text-uppercase text-weight-medium">
      {{ $t("dashboard.graph_title") }}
    </div>
    <vue3-highcharts
      type="chart"
      :options="chartData"
      :redrawOnUpdate="true"
      :oneToOneUpdate="false"
      :animateOnUpdate="true"
      @rendered="onRender"
      @update="onUpdate"
      @destroy="onDestroy"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import VueHighcharts from "vue3-highcharts";
import { ref, computed } from "vue";

const { useActions, useGetters } = createNamespacedHelpers("home");

export default {
  name: "Graph",

  components: { "vue3-highcharts": VueHighcharts },

  setup() {
    const in_request = ref(true),
      { data } = useGetters({ data: "getVisitantsGraphData" }),
      { fetchGraphVisitor } = useActions(["fetchGraphVisitor"]);

    fetchGraphVisitor().finally(() => (in_request.value = false));

    const chartData = computed(() => {
      return {
        chart: {
          backgroundColor: "white",
          type: "spline",
          height: 220,
        },
        title: {
          text: null,
        },
        yAxis: {
          labels: {
            enabled: false,
          },
          title: {
            text: null,
          },
          gridLineColor: "transparent",
        },
        xAxis: {
          labels: {
            enabled: false,
          },
          visible: false,
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false,
              symbol: "circle",
              radius: 0,
            },
          },
          spline: {
            color: "#8ebce4",
            dataLabels: {
              enabled: true,
              formatter: function() {
                /*return `R$ ${this.y.toFixed(2)}`*/
                return this.y > 0 ? parseInt(this.y) : "";
              },
            },
            enableMouseTracking: true,
          },
          areaspline: {
            fillOpacity: 0.5,
          },
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: "500px",
                maxHeight: "100px",
              },
            },
          ],
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "",
            data: data.value,
          },
        ],
      };
    });

    const fakeChart = () => {
      let series = [];
      while (series.length < 15) {
        series.push(Math.floor(Math.random() * 100) + 1);
      }

      let fake = JSON.parse(JSON.stringify(chartData.value));
      fake.plotOptions.spline.dataLabels.enabled = false;
      fake.plotOptions.spline.color = "grey";
      fake.chart.backgroundColor = "#eeeeee";
      fake.series = [
        {
          name: "",
          data: series,
        },
      ];

      return fake;
    };

    const onRender = () => {
        console.log("Chart rendered");
      },
      onUpdate = () => {
        console.log("Chart updated");
      },
      onDestroy = () => {
        console.log("Chart destroyed");
      };

    return {
      in_request,
      chartData,
      fakeChart,
      onRender,
      onUpdate,
      onDestroy,
    };
  },
};
</script>

<style scoped lang="scss">
.vue-highcharts {
  width: 100%;
}
</style>
