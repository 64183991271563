<template>
  <default-dashboard v-if="partner.slug !== 'flipnet'" />
  <new-dashboard v-else />
</template>

<script setup>
import {
  createNamespacedHelpers,
  useActions,
  useStore,
} from "vuex-composition-helpers";
import DefaultDashboard from "./dashboardTypes/DefaultDashboard";
import NewDashboard from "./dashboardTypes/NewDashboard";
import { onMounted } from "vue";

const store = useStore();

const partnerNamespace = createNamespacedHelpers("partner");
const { fetchCompanyScripts } = useActions({
  fetchCompanyScripts: "home/fetchCompanyScripts",
});

const { partner } = partnerNamespace.useGetters({
  partner: "getPartner",
});

/*
const applyScriptToHeader = (content) => {
  if (content) {
    const scriptElement = document.createElement("script");
    scriptElement.async = true;
    console.log(content);
    scriptElement.src = content.match(/src="([^"]+)"/)[1];
    document.head.appendChild(scriptElement);
  }
};
*/

onMounted(() => {
  fetchCompanyScripts().then((data) => {
    if (Object.keys(data).length !== 0 && data.content) {
      // applyScriptToHeader(data.content);
      store.commit("home/SET_HAS_SCRIPTS", true);
    }
  });
});
</script>

<style lang="scss">
.mobile {
  .dash-grid {
    [class*="col-"] {
      width: 100%;
    }
  }
}
</style>
