<template>
  <div
    class="card-products q-pa-md text-ds-grey"
    :class="{ 'bg-grey-3': in_request, 'bg-white': !in_request }"
  >
    <page-title
      class="q-mb-lg text-ds-grey"
      :title-class="{ 'title-small no-margin': true }"
      :title="$t('dashboard.products_title')"
      :tooltip="paramsProductHelp"
      :floating="false"
    >
    </page-title>
    <div class="column q-pt-lg full-width" v-if="in_request">
      <div
        class="width-fit-content-flex full-width product-width"
        v-for="n in 4"
        :key="`xs-skt-${n}`"
      >
        <product-item-store :skeleton="true" />
      </div>
    </div>
    <div class="column q-pt-lg full-width" v-else>
      <div
        class="width-fit-content-flex full-width product-width"
        v-for="(product, n) in products"
        :key="`xs-${n}`"
      >
        <product-item-store :product="product" />
      </div>
      <q-item :to="{ name: 'store' }" class="text-center">
        <q-item-section>{{ $t("global.show_more") }}</q-item-section>
      </q-item>
    </div>
  </div>
</template>
<script>
import ProductItemStore from "@/modules/main/components/productItemStore";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import PageTitle from "@/modules/main/components/page-title";
import { computed, ref } from "vue";
import { take } from "lodash";

const namespacedPartner = createNamespacedHelpers("partner");
const { useGetters, useActions } = createNamespacedHelpers("products");

export default {
  name: "Products",

  components: { ProductItemStore, PageTitle },

  setup() {
    const { store_products, favorite_products } = useGetters({
        favorite_products: "getFavoritesProducts",
        store_products: "getStoreProducts",
      }),
      { paramsProductHelp } = namespacedPartner.useGetters({
        paramsProductHelp: "getParamsProductHelp",
      });

    const in_request = ref(true),
      products = computed({
        get: () => {
          if (favorite_products.value.length)
            return take(favorite_products.value, 4);
          else return take(store_products.value, 4);
        },
      });

    const {
      fetchFavoriteProductsAction,
      fetchStoreProductsAction,
    } = useActions([
      "fetchFavoriteProductsAction",
      "fetchStoreProductsAction",
      "setFilterValue",
    ]);

    fetchFavoriteProductsAction().finally(() => {
      if (favorite_products.value.length === 0) {
        fetchStoreProductsAction().finally(() => (in_request.value = false));
      } else in_request.value = false;
    });

    return {
      in_request,
      products,
      PageTitle,
      paramsProductHelp,
    };
  },
};
</script>
<style lang="scss" scoped>
.card-products .title {
  text-transform: uppercase;
  font-size: 15px;
}
.mobile {
  .card-products {
    font-size: 11px;
    .ic-share {
      flex: none;
      width: 47px;
    }
  }
}
</style>
