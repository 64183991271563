<template>
  <DashAreaComponent title="Total em cŕeditos" :titleBorder="false">
    <div style="margin-top: -15px;">
      <vue3-autocounter
        :duration="1"
        separator="."
        :decimals="2"
        ref="counter"
        :autoinit="true"
        :startAmount="0"
        :endAmount="creditData.points_received"
        decimalSeparator=","
        class="text-counter-size"
        v-if="!in_request"
      />
      <q-skeleton v-else name="text" style="width: 100px;" animation="none" />
    </div>
    <div class="row q-mt-sm">
      <div class="col-12 col-md-6 text-uppercase text-grey-9 q-pr-sm">
        <div v-if="in_request" class="row">
          Resgatados:
          <q-skeleton
            name="text"
            class="q-ml-md"
            animation="none"
            style="width: 100px; margin-top: -2px;"
          />
        </div>
        <template v-else>
          Resgatados:
          <vue3-autocounter
            :duration="1"
            separator="."
            :decimals="2"
            ref="counter"
            :autoinit="true"
            :startAmount="0"
            :endAmount="creditData.points_rescued"
            decimalSeparator=","
            class="text-counter-size2"
          />
        </template>
        <!--
        <q-btn
          dense
          outline
          color="black"
          icon="receipt_long"
          label="Extrato"
          class="full-width q-mt-md"
          @click="redirect('/my-points/extracts')"
        />
        -->
      </div>

      <div
        class="col-12 col-md-6 text-uppercase text-grey-9 q-pl-sm"
        :class="{
          'border-left': !isMobile,
        }"
      >
        <div
          class="text-subtitle2 text-red"
          :class="{
            'expire-value-position-desktop': !isMobile,
            'expire-value-position-mobile': isMobile,
          }"
        >
          <div v-if="in_request" class="row">
            A expirar:
            <q-skeleton
              name="text"
              class="q-ml-sm"
              animation="none"
              style="width: 100px; margin-top: -2px;"
            />
          </div>
          <div v-else>
            A expirar:
            {{
              creditData.points_expire_soon > 0
                ? creditData.points_expire_soon
                : "0,00"
            }}
          </div>
        </div>
        <div v-if="in_request" class="row">
          Resgatar:
          <q-skeleton
            name="text"
            class="q-ml-sm"
            animation="none"
            style="width: 100px; margin-top: 2px;"
          />
        </div>
        <div v-else>
          Resgatar:
          <vue3-autocounter
            :duration="1"
            separator="."
            :decimals="2"
            ref="counter"
            :autoinit="true"
            :startAmount="0"
            :endAmount="creditData.balance"
            decimalSeparator=","
            class="text-counter-size2"
          />
        </div>
        <!--
        <q-btn
          dense
          outline
          color="black"
          icon="payments"
          label="Resgatar"
          class="full-width q-mt-md"
          @click="redirect('/my-points/award')"
        />
        -->
      </div>
    </div>
    <div class="row">
      <q-icon
        size="sm"
        class="q-ml-auto"
        style="cursor: pointer;"
        @click="changeVisibility()"
        :class="{
          'margin-show': !in_request,
          'margin-block': in_request,
        }"
        :name="in_request ? 'visibility' : 'visibility_off'"
      />
    </div>
  </DashAreaComponent>
</template>

<script>
import DashAreaComponent from "./DashAreaComponent.vue";
import Vue3autocounter from "vue3-autocounter";
import { useRouter } from "vue-router";
import { useQuasar } from "quasar"; 
import { ref } from "vue";

export default {
  name: "CreditDataComponent",

  props: {
    creditData: {
      type: Object,
      required: true,
    },
    in_request: {
      type: Boolean,
      required: true,
      default: true,
    },
  },

  components: { DashAreaComponent, "vue3-autocounter": Vue3autocounter },

  emits: ["changeVisibility"],

  setup(props, { emit }) {
    const $q = useQuasar();
    const router = useRouter();
    const visibility = ref(true);
    const isMobile = $q.screen.width < 700;

    return {
      isMobile,
      visibility,

      redirect: (endpoint) => router.push(endpoint),

      changeVisibility: () => {
        visibility.value = !visibility.value;
        emit("changeVisibility", visibility.value);
      },
    };
  },
};
</script>

<style scoped>
.text-counter-size {
  font-size: 20px;
  font-weight: 300;
  color: #000000;
}

.text-counter-size2 {
  font-size: 11.5pt;
  color: #454545;
}

.border-left {
  border-left: 1px solid grey;
}

.expire-value-position-desktop {
  margin-top: -22.5px;
  font-size: 8pt;
}

.expire-value-position-mobile {
  margin-top: 15px;
  font-size: 8pt;
}

.margin-show {
  margin-top: -85px;
}
.margin-block {
  margin-top: -75px;
}
</style>
