<template>
  <q-card flat class="default-rounded full-height card-border">
    <q-item class="q-mt-sm">
      <q-item-section avatar>
        <img style="width: 60px" :src="data.partner.logo_foto_link" />
      </q-item-section>

      <q-item-section>
        <q-item-label class="text-black text-uppercase">{{
          data.partner.nome
        }}</q-item-label>
        <q-item-label caption lines="3" class="text-justify text-grey-8">{{
          data.short_description
        }}</q-item-label>
      </q-item-section>
    </q-item>
    <img
      :src="
        data.asset ? data.asset.url : 'https://via.placeholder.com/1280x720'
      "
      class="q-pr-md q-pl-md q-mt-md imgHeight"
      :ratio="16 / 9"
    />

    <q-card-section class="row">
      <q-badge color="grey" v-if="data.start_at">
        <span class="date_label"
          ><q-icon name="event" />
          {{
            `De ${formatDate(data.start_at)} até ${formatDate(data.end_at)}`
          }}</span
        >
      </q-badge>
      <q-space />
      <cant-subs-on-mission :data="data" />
    </q-card-section>
  </q-card>
</template>

<script setup>
import { formatDate } from "@/shared/helpers/numbersManipulation";
import CantSubsOnMission from "./CantSubsOnMission.vue";
import { defineProps } from "vue";

defineProps({
  data: {
    type: Object,
    required: true,
  },
});
</script>

<style lang="scss" scoped>
.card-border {
  border: 1px solid #5a5a5a;
}
.date_label {
  font-size: 7pt !important;
}

.imgHeight {
  height: 270px;
}

@media screen and (max-width: 1600px) {
  .imgHeight {
    height: 210px;
  }
}

@media screen and (max-width: 1440px) {
  .imgHeight {
    height: 185px;
  }
}

@media screen and (max-width: 1280px) {
  .imgHeight {
    height: 150px;
  }
}

@media screen and (max-width: 800px) {
  .imgHeight {
    height: 140px;
  }
}
</style>
