<template>
  <div
    class="bg-red-4 text-white"
    v-if="pending_docs.percent < 100 && showComponent"
  >
    <div class="row" v-if="$q.screen.width > 800">
      <div class="col-md-1 text-center">
        <q-circular-progress
          show-value
          size="48px"
          :value="pending_docs.percent"
          font-size="10px"
          :thickness="0.4"
          color="green-5"
          track-color="red-4"
          class="q-ma-md"
        >
          {{ pending_docs.percent }}%
        </q-circular-progress>
      </div>
      <div class="col-md-11">
        <div class="q-pt-md">
          {{ $t("dashboard.pending_docs") }}<br />
          <a
            href="#"
            class="show-link"
            @click="openModal()"
            v-html="$t('dashboard.click_to_Show')"
          />
        </div>
      </div>
    </div>
    <div class="row q-pt-md q-pb-md" v-else>
      <div class="q-mr-auto q-ml-auto">
        <q-circular-progress
          show-value
          size="55px"
          :value="pending_docs.percent"
          font-size="11px"
          :thickness="0.4"
          color="green-5"
          track-color="red-4"
        >
          {{ pending_docs.percent }}%
        </q-circular-progress>
      </div>

      <div class="q-ma-md text-justify">
        <span
          >{{ $t("dashboard.pending_docs") }}
          <br />
        </span>
        <div class="text-center q-mt-lg">
          <a
            href="#"
            class="show-link"
            @click="openModal()"
            v-html="$t('dashboard.click_to_Show')"
          />
        </div>
      </div>
    </div>
  </div>
  <docs-pend-component
    v-model="showModal"
    :docs_pend="pending_docs.docs"
    :label="'dashboard.pending_docs_message'"
  />
</template>

<script>
import docsPendComponent from "../../rescue-award/components/docsPendComponent";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { onMounted, ref } from "vue";

const { useGetters, useActions } = createNamespacedHelpers("home");

export default {
  name: "PendingDocs",

  components: {
    docsPendComponent,
  },

  setup() {
    const showComponent = ref(false);
    const showModal = ref(false);

    const { pending_docs } = useGetters({
        pending_docs: "getDocsPendingDash",
      }),
      { fetchDocsPendingDash } = useActions(["fetchDocsPendingDash"]);

    onMounted(() => {
      fetchDocsPendingDash().then(() => {
        showComponent.value = true;
      });
    });

    return {
      showModal,
      pending_docs,
      showComponent,
      openModal: () => (showModal.value = true),
    };
  },
};
</script>

<style lang="scss" scoped>
.show-link {
  color: #fff;
  font-weight: 700;
}
.show-link:hover {
  color: #163add;
  transition: 0.4s;
}
</style>
