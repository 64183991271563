<template>
    <q-dialog class="dialog-date" ref="dialogRef" @hide="onDialogHide" persistent>
        <q-card class="q-dialog-plugin">
            <q-card-section>
                <h6 class="q-mt-none q-mb-lg q-mx-none">Selecione o período</h6>
                <q-date class="q-date-in-dialog" :title="titleDateHeader" :minimal="hideDateHeader" v-model="model" range :options="calendarOptions" />
            </q-card-section>
            <q-card-actions align="right">
                <q-btn flat label="Cancelar" @click="dialogHide" />
                <q-btn class="button-date-in-dialog-ok" label="Ok" @click="onOKClick" />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>
<script setup>
import { useDialogPluginComponent, useQuasar } from 'quasar'
import { ref, defineEmits, defineProps, watch } from 'vue';
import moment from 'moment'
import { americanFormat, calculateDaysBetweenDates, isDateValid } from '../../../../../shared/helpers/numbersManipulation';

const $q = useQuasar();

defineEmits([
    ...useDialogPluginComponent.emits
])

const props = defineProps({
    listeningCustomDate: {
        type: Function,
        required: true
    },
    method: {
        type: String
    }
})

const model = ref({ 
    from: '', 
    to: ''
});

const hideDateHeader = ref(true);
const titleDateHeader = ref('');

watch(model, (newValue) => {
    if ( !newValue ) return hideDateHeader.value = true

    const from = newValue.from,
    to = newValue.to;

    if ( !from || !to ) return hideDateHeader.value = true
    
    hideDateHeader.value = false
    titleDateHeader.value = `${calculateDaysBetweenDates(from, to)} dias`
});

const currentDate = moment().format('YYYY/MM/DD'),
thirtyDaysAgo = moment().subtract(89, 'days').format('YYYY/MM/DD');

const { dialogRef, onDialogHide, onDialogOK } = useDialogPluginComponent();

// METHODS

// Essa função fecha o dialog ( Caso passe pelas validaçõs ) e retorna as datas selecionadas para o Componente Pai
function onOKClick() {

    if ( !model.value?.from || !model.value?.to ) return dialogDateInvalid();

    model.value = { 
        created_at_begin: americanFormat(model.value.from), 
        created_at_end: americanFormat(model.value.to)
    }

    if ( !checkDateIsValid() ) return dialogDateInvalid();

    props.listeningCustomDate(model.value)
    onDialogOK()
}

// Essa função fecha o dialog e retorna null para o Componente Pai fazer o tratamento.
const dialogHide = () => {
    props.listeningCustomDate(null)
    onDialogHide()
}

// Configuração do componente calendário do Quasar
const calendarOptions = (date) => {
    return date >= thirtyDaysAgo && date <= currentDate
}

// Chamar essa função em tratamentos de data inválida
const dialogDateInvalid = () => {
    $q.dialog({
        title: 'Ops!',
        message: 'Por favor, selecione uma data de início e fim válidas para o período.'
    })
}

// Essa função válida se as datas selecionadas são válidas ( Se o user selecionou inicio e fim por ex )
const checkDateIsValid = () => {
    if ( 
        !isDateValid(model.value.created_at_begin, 'YYYY-MM-DD') ||
        !isDateValid(model.value.created_at_end, 'YYYY-MM-DD') 
    ) return false;

    if ( model.value.created_at_begin === model.value.created_at_end ) return false

    return true
}
</script>
<style>
    .q-date__header,
    .q-date__calendar-item--in > button.bg-primary {
        background-color: var(--primary-custom-color) !important;
    }
    
    .q-date__range.text-primary,
    .q-date__range-from.text-primary, 
    .q-date__range-to.text-primary,
    .q-date__calendar-item.text-primary,
    .q-date__calendar-item--in > button.text-primary
    {
        color: var(--primary-custom-color) !important;
    }

    .q-date {
        margin: 0 auto !important;
        width: auto !important;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px !important;
    }

    .q-date__range-from .q-btn, .q-date__range-to .q-btn {
        background-color: var(--primary-custom-color) !important;
    }

    .button-date-in-dialog-ok {
        background-color: var(--primary-custom-color) !important;
        background: var(--primary-custom-color) !important;
        color: #fff !important
    }
</style>