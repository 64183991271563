<template>
  <q-btn
    outline
    color="black"
    class="q-pa-sm"
    :class="{
      'full-width': $q.screen.width <= 800,
    }"
    v-if="displaysClusteredParts?.show_cupom && Object.keys(getVoucher).length"
    @click="copy(getVoucher.number)"
  >
    <q-icon name="sell" />
    <span v-text="getVoucher.number" />
    <i class="fas fa-copy" style="font-size: 1.2rem"></i>
  </q-btn>
  <q-btn
    v-if="displaysClusteredParts?.show_store"
    outline
    color="black"
    class="q-pa-sm"
    :class="{
      'q-ml-sm': $q.screen.width > 800,
      'full-width q-mt-sm': $q.screen.width <= 800,
    }"
  >
    <q-icon name="store" />
    <span>Sua loja</span>
    <q-icon name="more_vert" />
    <q-menu fit>
      <card-store
        v-if="link_store"
        :is_cluster="true"
        :show_qrcode="displaysClusteredParts?.show_qrcode"
        :link_store="link_store"
        :hideCopyLink="true"
      />
    </q-menu>
  </q-btn>
</template>

<script setup>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import CardStore from "@/modules/main/views/card-store";
import { notifySuccess } from "@/shared/helpers/notify";
import { copyToClipboard } from "quasar";
import { defineProps } from "vue";

defineProps({
  displaysClusteredParts: {
    type: Object,
    required: true,
  },
});

const { useGetters } = createNamespacedHelpers("home");
const partnerStore = createNamespacedHelpers("partner");

const { getVoucher } = useGetters(["getVoucher"]);

const { link_store } = partnerStore.useGetters({
  link_store: "getStoreLink",
});

const copy = (value) => {
  notifySuccess("Copiado para a área de transferência");
  copyToClipboard(value);
};
</script>
<style lang="scss" scoped>
:deep(.q-btn__content) {
  display: flex;
  justify-content: space-evenly;
  width: 200px;
  margin: 0 auto;
}

:deep(.q-btn__content > span) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 95px;
  width: 95px;
}
</style>
