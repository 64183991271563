<template>
  <DashAreaComponent title="Vendas Aprovadas/Faturadas" 
  :titleBorder="false"
  :tooltipText="$t('dashboard.tooltip_message.approved_sales')"
  >
    <div class="q-mt-sm">
      <vue3-autocounter
        v-if="!in_request"
        class="text-counter-size"
        ref="counter"
        :startAmount="0"
        :endAmount="parseFloat(totalOrders)"
        :duration="1"
        :prefix="$t('global.moneyTypeLabel') + ' '"
        separator="."
        decimalSeparator=","
        :decimals="2"
        :autoinit="true"
      />
      <q-skeleton v-else name="text" class="q-pa-xs" style="width: 100px" />
    </div>
    <q-btn
      dense
      color="black"
      label="Ver minhas vendas"
      class="full-width q-mt-md"
      @click="redirect('/sales')"
    />
  </DashAreaComponent>
</template>

<script>
import Vue3autocounter from "vue3-autocounter";
import DashAreaComponent from "./DashAreaComponent.vue";
import { useRouter } from "vue-router";

export default {
  name: "TotalSalesComponent",

  props: {
    totalOrders: {
      type: Number,
      required: true,
    },
    in_request: {
      type: Boolean,
      required: true,
      default: true,
    },
  },

  components: { DashAreaComponent, "vue3-autocounter": Vue3autocounter },

  setup() {
    const router = useRouter();
    return {
      redirect: (endpoint) => {
        router.push(endpoint);
      },
    };
  },
};
</script>

<style scoped>
.text-counter-size {
  font-size: 20px;
  font-weight: 300;
  color: #2b343f;
}
</style>
