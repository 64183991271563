<template>
  <q-dialog persistent v-model="carousel" content-class="no-scrollbar">
    <q-card class="card">
      <q-bar class="bg-black q-mb-md" />
      <q-card-section class="q-pt-none" style="min-height: 50vh">
        <template v-for="(item, i) in checklists" :key="i">
          <div v-if="currentItem == i">
            <div v-if="item.text_html" v-html="item.text_html" />
            <div v-else>
              <q-img
                class="check-image"
                @click="redirect(item)"
                :src="
                  $q.screen.width < 800
                    ? item.mobile_banner_url
                    : item.desktop_banner_url
                "
              />
            </div>
          </div>
        </template>
      </q-card-section>

      <q-card-actions align="right" class="bg-white">
        <q-btn
          dense
          color="black"
          label="Voltar"
          icon="chevron_left"
          @click="currentItem--"
          v-if="currentItem"
        />
        <q-btn
          dense
          color="black"
          label="Avançar"
          icon-right="chevron_right"
          @click="currentItem++"
          v-if="currentItem < checklists.length - 1"
        />
        <q-btn
          dense
          color="black"
          label="Concluir"
          @click="checkedOnClick"
          v-if="currentItem == checklists.length - 1"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { defineComponent, ref, onMounted } from "vue";
import { notifyError } from "@/shared/helpers/notify";

const componentsNamespace = createNamespacedHelpers("components");

export default defineComponent({
  name: "firstAcessTutorial",

  setup() {
    const currentItem = ref(0);

    const carousel = ref(false),
      onRequest = ref(false);

    const { fetchChecklistsAction, checkUpChecklistAction } =
      componentsNamespace.useActions({
        fetchChecklistsAction: "fetchChecklistsAction",
        checkUpChecklistAction: "checkUpChecklistAction",
      });

    const { checklists } = componentsNamespace.useGetters({
      checklists: "getChecklists",
    });

    const checkedOnClick = () => {
      onRequest.value = true;

      checklists.value.forEach((ck) => {
        checkUpChecklistAction(ck)
          .then(() => (carousel.value = false))
          .catch(() => notifyError())
          .finally(() => (onRequest.value = false));
      });
    };

    const verifyChecklist = () => {
      checklists.value.forEach((ck) => {
        if (ck.checked == false) carousel.value = true;
      });
    };

    onMounted(
      async () => await fetchChecklistsAction().then(() => verifyChecklist())
    );

    return {
      currentItem,
      checklists,
      onRequest,
      carousel,

      checkedOnClick,
      verifyChecklist,
      redirect: (item) => window.open(item.video_url, "_blank"),
    };
  },
});
</script>

<style scoped>
.card {
  min-width: 800px;
  max-width: 80vw;
}

@media (max-width: 800px) {
  .card {
    min-width: 90vw;
  }
}
</style>
