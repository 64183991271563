<template>
  <div class="full-height bg-white">
    <DashAreaComponent
      :title="$t('global.checklists.actions_to_spread')"
      :titleBorder="false"
    >
      <div class="q-mr-md q-ml-md" v-if="checklists.length">
        <div
          class="q-mt-sm"
          v-for="checklist in checklists"
          :key="checklist.id"
        >
          <div
            :class="{ 'bg-grey-4': checklist.checked }"
            class="q-pl-md lists"
          >
            <checklist :checklist="checklist" />
          </div>
        </div>
      </div>
      <div
        class="text-center bg-grey-4 default-rounded q-pa-xl"
        style="height: 100%;"
        v-else
      >
        <p class="q-ma-lg voucher-code">
          Não há ações disponíveis.
        </p>
      </div>
    </DashAreaComponent>
  </div>
</template>

<script>
import DashAreaComponent from "../pages/home/components/DashAreaComponent.vue";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import Checklist from "./checklist";

const { useGetters } = createNamespacedHelpers("components");

export default {
  name: "Checklists",

  components: { Checklist, DashAreaComponent },

  setup() {
    const { checklists } = useGetters({
      checklists: "getChecklists",
    });

    return {
      checklists,
    };
  },
};
</script>

<style scoped lang="scss">
.lists {
  > div {
    margin: 0 auto;
  }
  max-height: 200px;
  overflow-y: auto;
  border-left: 3px solid #505050;
  border-bottom: 1px solid #bcbcbc;
}

.voucher-code {
  font-size: 1.2em;
  color: #5d5d5d;
}
</style>
