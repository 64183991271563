<template>
  <div :class="{ done: isDone }" class="row no-wrap checklist justify-between">
    <div class="q-gutter-sm no-wrap row items-center no-ripple-checkbox">
      <q-item-label class="text-grey-8 text-weight-bolder">
        {{ checklist.sequence }}º
      </q-item-label>
      <q-checkbox
        @click="checkedOnClick"
        v-model="isDone"
        color="grey-7"
        keep-color
        size="sm"
      />
      <q-item-label
        :class="{ 'text-underline cursor-pointer': !!checklist.url }"
        @click="listClick"
        class="text-grey-9"
      >
        {{ checklist.description }}
      </q-item-label>
    </div>
    <div class="q-pa-sm row no-wrap">
      <q-icon
        v-if="checklist.video_url"
        @click="openVideo"
        class="cursor-pointer q-ml-sm"
        size="1.5em"
        color="red"
        name="fab fa-youtube"
      />
      <q-icon
        v-if="checklist.help_description"
        class="cursor-pointer q-ml-sm"
        size="1.5em"
        color="indigo-4"
        name="fas fa-question-circle"
      >
        <q-tooltip :offset="[10, 10]" class="bg-indigo-4">
          {{ checklist.help_description }}
        </q-tooltip>
      </q-icon>
      <q-icon
        v-if="banner"
        @click="seamless = !seamless"
        class="cursor-pointer q-ml-sm"
        size="1.5em"
        color="grey-7"
        name="view_carousel"
      />
    </div>
    <q-dialog v-if="banner" v-model="seamless" position="standard">
      <div
        :style="$q.platform.is.desktop ? 'width: 700px; max-width: 80vw;' : ''"
        class="column full-width no-wrap no-shadow q-ma-lg"
      >
        <q-btn
          class="self-end"
          color="white"
          icon="close"
          flat
          round
          dense
          v-close-popup
        />
        <q-img
          class="cursor-pointer"
          @click="listClick"
          fit="contain"
          :src="banner"
        >
        </q-img>
      </div>
    </q-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { notifyError } from "@/shared/helpers/notify";
import { Platform } from "quasar";
import { ref, watch } from "vue";

const { useActions } = createNamespacedHelpers("components");

export default {
  name: "Checklist",

  props: {
    checklist: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const onRequest = ref(false),
      banner = ref(""),
      seamless = ref(false),
      isDone = ref(props.checklist.checked),
      { checkUpChecklistAction, undoChecklistAction } = useActions([
        "checkUpChecklistAction",
        "undoChecklistAction",
      ]);

    watch(
      () => props.checklist,
      (newChecklist) => {
        isDone.value = newChecklist.checked;
      }
    );

    if (Platform.is.mobile) {
      banner.value =
        props.checklist.mobile_banner_url || props.checklist.desktop_banner_url;
    } else {
      banner.value =
        props.checklist.desktop_banner_url || props.checklist.mobile_banner_url;
    }

    const checkedOnClick = () => {
      onRequest.value = true;
      let action = isDone.value
        ? checkUpChecklistAction(props.checklist)
        : undoChecklistAction(props.checklist);
      action
        .catch(() => notifyError())
        .finally(() => {
          onRequest.value = false;
        });
    };

    const listClick = () => {
        if (props.checklist.url) {
          window.open(props.checklist.url, props.checklist.url_target);
        }
      },
      openVideo = () => {
        window.open(props.checklist.video_url, "_blank");
      };

    return {
      banner,
      isDone,
      seamless,
      openVideo,
      listClick,
      checkedOnClick,
    };
  },
};
</script>

<style scoped lang="scss">
.done {
  opacity: 0.5;
  position: relative;
  &:before {
    content: "";
    width: 100%;
    height: 1px;
    background: #c7c7c7;
    position: absolute;
    top: 19px;
  }
  .text-underline {
    text-decoration: none !important;
  }
}
</style>
