<template>
  <div v-if="getCompaniesList.length" class="q-mb-lg">
    <DashAreaComponent
      v-if="!in_request"
      :titleBorder="false"
      :title="$t('dashboard.flip.my_companies')"
      :tooltipText="$t('dashboard.flip.my_companies_help')"
    >
      <div class="row">
        <div
          class="bg-grey-4 full-width q-pa-md default-rounded"
          v-html="$t('dashboard.flip.my_companies_tooltip')"
        />
        <div class="col-12">
          <carousel-multi-itens-component
            :isFullHeight="false"
            :data="getCompaniesList"
            :group-size="$q.screen.width > 900 ? 4 : 1"
            :group-grid="$q.screen.width > 900 ? 'col-md-3' : 'col-md-12'"
            :item-name="'CardFlipPartnerSubscribe'"
          />
        </div>
      </div>
    </DashAreaComponent>
    <DashAreaComponent
      v-else
      :titleBorder="false"
      :title="$t('dashboard.flip.my_companies')"
    >
      <div class="row">
        <div class="full-width">
          <carousel-multi-itens-component
            :isFullHeight="false"
            :data="[1, 2, 3, 4]"
            :group-size="$q.screen.width > 900 ? 4 : 1"
            :group-grid="$q.screen.width > 900 ? 'col-md-3' : 'col-md-12'"
            :item-name="'CardFlipPartnerSubscribeSkeleton'"
          />
        </div>
      </div>
    </DashAreaComponent>
  </div>
</template>

<script setup>
import CarouselMultiItensComponent from "../components/CarouselMultiItensComponent.vue";
import DashAreaComponent from "../components/DashAreaComponent.vue";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { defineProps } from "vue";
import { useQuasar } from "quasar";

const homeNamespace = createNamespacedHelpers("home");

defineProps({
  in_request: {
    type: Boolean,
    required: true,
  },
});

const $q = useQuasar();

const { getCompaniesList } = homeNamespace.useGetters(["getCompaniesList"]);
</script>
