<template>
  <DashAreaComponent
    :title="$t('dashboard.flip.opportunities')"
    :titleBorder="false"
  >
    <div class="row">
      <q-btn
        color="black"
        :label="isMobile ? 'Missões' : 'Minhas missões'"
        class="q-ml-auto btn-missions"
        @click="redirect"
      />
    </div>
    <div class="row">
      <div v-if="getAvaibleMissions.length">
        <carousel-multi-itens-component
          :isFullHeight="false"
          :data="getAvaibleMissions"
          :group-size="$q.screen.width > 900 ? 3 : 1"
          :group-grid="$q.screen.width > 900 ? 'col-md-4' : 'col-md-12'"
          :item-name="'CardFlipPartnerMission'"
        />
      </div>
      <div class="full-width" v-else>
        <carousel-multi-itens-component
          :isFullHeight="false"
          :data="[1, 2, 3]"
          :group-size="$q.screen.width > 900 ? 3 : 1"
          :group-grid="$q.screen.width > 900 ? 'col-md-4' : 'col-md-12'"
          :item-name="'CardFlipPartnerMissionSkeleton'"
        />
      </div>
    </div>
  </DashAreaComponent>
</template>

<script>
import CarouselMultiItensComponent from "../components/CarouselMultiItensComponent.vue";
import DashAreaComponent from "../components/DashAreaComponent.vue";

import { createNamespacedHelpers } from "vuex-composition-helpers";
import { defineComponent } from "vue";
import { useQuasar } from "quasar";

const missionNamespace = createNamespacedHelpers("missions");

export default defineComponent({
  name: "AvaibleMissionsComponent",

  components: {
    DashAreaComponent,
    CarouselMultiItensComponent,
  },

  setup() {
    const $q = useQuasar();
    const isMobile = $q.screen.width < 700;

    const { getAvaibleMissions } = missionNamespace.useGetters([
      "getAvaibleMissions",
    ]);

    return {
      isMobile,
      getAvaibleMissions,
      redirect: () => window.open(`/missions`, "_blank"),
    };
  },
});
</script>

<style scoped>
.btn-missions {
  margin-top: -40px;
}
</style>
