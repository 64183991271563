<template>
  <div class="column justify-between q-pa-md bg-white text-ds-grey">
    <q-scroll-area style="height: 400px">
      <page-title
        class="q-mb-lg"
        :title="$t('dashboard.last_points_released')"
        :title-class="{ 'title-small': true }"
        :floating="false"
        :tooltip="$t('dashboard.invite_help')"
      >
      </page-title>
      <q-table
        id="table_last_points"
        :loading="in_request"
        :columns="columns"
        :bordered="false"
        :rows="rows"
        flat
        row-key="name"
      >
      </q-table>
    </q-scroll-area>
    <div class="row justify-center full-width">
      <router-link :to="{ name: 'my-points' }" class="link">{{
        $t("global.show_extract")
      }}</router-link>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import PageTitle from "../../../components/page-title";
import moment from "moment";

const columns = [
  {
    name: "action",
    align: "center",
    label: "AÇÃO",
    field: "action",
    sortable: true,
  },
  {
    name: "amount",
    required: true,
    label: "PONTOS",
    field: (row) => row.amount,
    format: (val) => `${val}`,
    sortable: true,
  },
  {
    name: "created_at",
    label: "DATA DO LANÇAMENTO",
    format: (val) => moment(val).format("DD/MM/YYYY"),
    field: (row) => row.created_at,
    sortable: true,
  },
];

const { useActions, useGetters } = createNamespacedHelpers("my_points");

export default {
  name: "LastPointsReleased",

  components: { PageTitle },

  setup() {
    const { rows, in_request } = useGetters({
        rows: "getPointsTypePoint",
        in_request: "isPointerInRequest",
      }),
      { fetchPoints } = useActions(["fetchPoints"]);

    fetchPoints();

    return {
      columns,
      rows,
      in_request,
    };
  },
};
</script>

<style lang="scss">
.link {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11pt;
  color: rgb(33, 100, 189);
}
#table_last_points {
  th {
    border: none !important;
  }
  tr {
    td {
      border: none !important;
    }
    border-color: transparent !important;
  }
}
</style>
