<template>
    <div class="card-voucher full-height bg-white" v-if="driveList"
    :style="partner.slug == 'flipnet' ? 'border-top: 5px solid grey' : ''"
    >
        <page-title
            :floating="false"
            :title-class="{ 'title-small': true }"
            :title="$t('dashboard.drive.title')"
            :tooltip="$t('dashboard.drive.tooltip_text')"
            class="q-mb-lg"
        />
        <div class="q-px-md">
            <ul class="q-px-lg q-py-none q-my-none">
                <li v-for="({ value }, index ) in driveList" :key="index">
                    <a v-if="typeof value === 'string'" :href="value" target="_blank">
                        <i style="font-size: 1.2rem" class="q-icon notranslate material-icons" role="img">open_in_new</i>
                        <span>Materiais para divulgação</span>
                    </a>
                    <a v-else :href="value.link" :key="index" target="_blank">
                        <i style="font-size: 1.2rem" class="q-icon notranslate material-icons" role="img">{{ value.icon }}</i>
                        <span v-text="value.description" />
                    </a>
                </li>
            </ul>
            <p class="q-mt-xl" v-text="$t('dashboard.drive.description')"/>
        </div>
    </div>
</template>
<script setup>
    import {
        useActions,
    } from "vuex-composition-helpers";
    import { createNamespacedHelpers } from "vuex-composition-helpers";

    import { ref } from 'vue';
    import pageTitle from '../../../components/page-title.vue';


    const driveList = ref(undefined);
    const partnerNamespace = createNamespacedHelpers("partner");

    const { partner } = partnerNamespace.useGetters({
      partner: "getPartner",
    });

    const { fetchDrives } = useActions({
        fetchDrives: "home/fetchDrives",
    });

    const dashLastRow = () => {

        /* Pega todos os cards que estão na mesma linha do Drive, 
        * para alterar dinamicamente as class de responsividade
        */
        const elements = Array.from(document.querySelectorAll('#dash-last-row > div'));

        if ( elements ) {
            elements.forEach(el => {
                if ( el.classList.contains('hidden') ) el.classList.remove('hidden');

                if ( el.classList.contains('col-8') ) {
                    el.classList.remove('col-8')
                    el.classList.add('col-4')
                } else if ( el.classList.contains('col-md-8')) {
                    el.classList.remove('col-md-8')
                    el.classList.add('col-md-4')
                }
            })
        }
    }

    fetchDrives({ keys:  ["link_drive_1", "link_drive_2", "link_drive_3"] })
    .then((output) => {

        if ( typeof output === "object" && Object.entries(output).length > 0 ) {

            const arrDrives = []

            for ( const drive in output ) {
                arrDrives.push(output[drive])
            }

            const linksWithValue = arrDrives.filter(find => find.value)

            if ( linksWithValue.length > 0 ) {
                driveList.value = linksWithValue

                dashLastRow()
            }
        }   
    })
    .catch((err) => {
        console.error('Houve um erro ao pegar Drives', err)
    })
</script>
<style scoped>
ul {
    width: 100%;
    display: grid;
    gap: 15px
}

li {
    display: flex;
    justify-content: start;
}

li a {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #79899d;
    text-align: left;
    min-height: 50px;
    padding: 15px 15px;
    color: #000;
    display: flex;
    gap: 10px;
    align-items: center;
    text-decoration: none;
}

li a .q-icon {
    position: relative;
    top: 0.02rem;
}
</style>