<template>
  <div
    class="q-mb-lg"
    :class="{
      'q-mr-md': !isMobile,
    }"
  >
    <carouselFlipComponent />
  </div>

  <div class="row">
    <div
      class="col-12 row q-mb-lg"
      :class="{
        'q-col-gutter-md': !isMobile,
      }"
    >
      <div class="col-12">
        <pending-docs />
      </div>
    </div>

    <div
      class="col-12 row"
      :class="{
        'q-col-gutter-md': !isMobile,
      }"
    >
      <div class="col-12">
        <AvaibleCompaniesComponent :in_request="getInRequestsDash.companies" />
      </div>
    </div>

    <div
      class="col-12 row"
      :class="{
        'q-col-gutter-md': !isMobile,
        'q-mt-md': isMobile,
      }"
      v-if="Object.keys(getCompaniesRegisteredSelect).length"
    >
      <div class="col-12 col-md-4">
        <q-select
          filled
          clearable
          class="bg-white"
          label="Selecionar marca"
          v-model="selectedCompany"
          :options="getCompaniesRegisteredSelect"
        >
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section>
                <q-item-label>{{ scope.opt.label }}</q-item-label>
                <q-item-label caption>{{ scope.opt.description }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>
    </div>

    <div
      class="col-12 row"
      :class="{
        'q-col-gutter-md': !isMobile,
        'q-mt-sm': !isMobile,
        'q-mt-md': isMobile,
      }"
    >
      <div class="col-12 col-md-4">
        <TotalSalesComponent
          :in_request="getInRequestsDash.totalSales"
          class="full-height default-card-border"
          :totalOrders="
            Object.keys(getTotalOrderDash).length
              ? getTotalOrderDash.value.sum_order
              : 0
          "
        />
      </div>
      <div
        class="col-12 col-md-8"
        :class="{
          'q-mt-md': isMobile,
        }"
      >
        <CreditDataComponent
          :in_request="getInRequestsDash.pointsSummary"
          class="full-height default-card-border"
          :creditData="getPointsSummary"
          @change-visibility="changeDataVisibility"
        />
      </div>
    </div>

    <div
      class="col-12 row"
      :class="{
        'q-col-gutter-md': !isMobile,
        'q-mt-sm': !isMobile,
        'q-mt-md': isMobile,
      }"
    >
      <div
        class="col-12 col-md-4"
        :class="{
          'q-mt-md': isMobile,
        }"
      >
        <MyVouchersComponent
          class="full-height default-card-border"
          :allCupons="getAllVouchers"
        />
      </div>
      <div
        class="col-12 col-md-4"
        :class="{
          'q-mt-md': isMobile,
        }"
      >
        <MyLinksComponent
          class="full-height default-card-border"
          :allLinks="getAllLinks"
        />
      </div>

      <div
        class="col-12 col-md-4"
        :class="{
          'q-mt-md': isMobile,
        }"
      >
        <invite class="full-height default-card-border" />
      </div>
    </div>

    <div
      class="col-12 row q-mt-md"
      id="dash-last-row"
      :class="{
        'q-col-gutter-md': !isMobile,
      }"
    >
      <div class="col-12 col-md-8">
        <checklists />
      </div>

      <div
        class="col-12 col-md-4 hidden"
        :class="{
          'q-mt-md': isMobile,
        }"
      >
        <drive />
      </div>

      <div
        class="col-12 col-md-4"
        :class="{
          'q-mt-md': isMobile,
        }"
      >
        <visitors-box-component />
      </div>
    </div>

    <div
      class="col-12 q-mt-md row"
      :class="{
        'q-col-gutter-md': !isMobile,
      }"
    >
      <div class="col-12">
        <AvaibleMissionsComponent />
      </div>
    </div>
  </div>
  <!-- Modals -->
  <!--
  <checklist-banner />
  -->
  <firstAcessTutorial />
  <ShareFlipnetComponent v-if="in_request_groups" />
</template>

<script setup>
import ShareFlipnetComponent from "../components/ShareFlipnetComponent.vue";
import AvaibleMissionsComponent from "../components/AvaibleMissionsComponent.vue";
import AvaibleCompaniesComponent from "../components/AvaibleCompaniesComponent.vue";
import visitorsBoxComponent from "../components/visitorsBoxComponent";
import TotalSalesComponent from "../components/TotalSalesComponent";
import CreditDataComponent from "../components/CreditDataComponent";
import MyVouchersComponent from "../components/MyVouchersComponent";
// import ChecklistBanner from "../../../components/checklistBanner";
import firstAcessTutorial from "../components/firstAcessTutorial";
import MyLinksComponent from "../components/MyLinksComponent";
import carouselFlipComponent from "../components/carouselFlipComponent";
import Checklists from "../../../components/checklists";
import PendingDocs from "../components/pending-docs";
import Invite from "../components/invite";
import drive from "../components/drive.vue";

import { createNamespacedHelpers } from "vuex-composition-helpers";
import { ref, onMounted, watch } from "vue";
import { useQuasar } from "quasar";

const homeNamespace = createNamespacedHelpers("home"),
  missionNamespace = createNamespacedHelpers("missions"),
  viewsNamespace = createNamespacedHelpers("views");

const selectedCompany = ref(null),
  in_request_groups = ref(false);

const $q = useQuasar(),
  isMobile = $q.screen.width < 700;

const {
  getAllLinks,
  getAllVouchers,
  getPointsSummary,
  getTotalOrderDash,
  getInRequestsDash,
  getCompaniesRegisteredSelect,
} = homeNamespace.useGetters([
  "getAllLinks",
  "getAllVouchers",
  "getPointsSummary",
  "getTotalOrderDash",
  "getInRequestsDash",
  "getCompaniesRegisteredSelect",
]);

const { fetchAvaibleMissions } = missionNamespace.useActions([
  "fetchAvaibleMissions",
]);
const { fetchParametersByKeys } = viewsNamespace.useActions([
  "fetchParametersByKeys",
]);

const { handleDashboardServices, fetchPointsSummary } =
  homeNamespace.useActions(["handleDashboardServices", "fetchPointsSummary"]);

const getFilter = (id) => ({
  ["filter[company_id]"]: id,
});

const fetchDashboardData = (id = null) => {
  $q.loading.show();
  if (id) handleDashboardServices(getFilter(id)).finally(() => onLoad());
  else handleDashboardServices().finally(() => onLoad());
};

const onLoad = () => setTimeout(() => $q.loading.hide(), 1000);

const changeDataVisibility = (e) => {
  const payload = selectedCompany.value
    ? getFilter(selectedCompany.value.id)
    : null;

  $q.loading.show();
  fetchPointsSummary({ payload, visibility: e }).finally(() =>
    $q.loading.hide()
  );
};

watch(selectedCompany, (newVal) =>
  fetchDashboardData(newVal ? newVal.id : null)
);

onMounted(() => {
  $q.loading.show();
  Promise.all([
    // Não descomentar a linha abaixo.
    // fetchDashboardData(),
    fetchAvaibleMissions(),
    fetchParametersByKeys({
      keys: ["whatsapp_group", "telegram_group"],
    }).finally(() => (in_request_groups.value = true)),
  ]).then(() => $q.loading.hide());
});
</script>
