<template>
  <div v-if="skeleton" class="card-product row justify-between items-center">
    <div class="card-picture cursor-pointer">
      <q-skeleton class="q-ma-sm" type="QToggle" />
    </div>
    <div class="q-ml-lg column width-fit-content-flex self-center">
      <q-skeleton class="q-mb-xs" />
      <q-skeleton width="50px" class="q-mb-xs" />
    </div>
  </div>
  <div v-else class="card-product row justify-between items-center">
    <div class="card-picture q-ma-xs cursor-pointer">
      <q-img width="70px" :src="prodImage" @error="onErrorImage" />
    </div>
    <div class="q-ml-lg column width-fit-content-flex self-center">
      <span>{{ product.name }}</span>
      <span class="q-mt-sm">{{ toMoneyLabel(product.price) }}</span>
    </div>
    <bar-share
      :drop-down-mode="$q.platform.is.mobile"
      :title="product.name"
      :link="product.shared_link"
      class="default-shadow default-rounded"
      style="overflow: hidden; max-width: 170px; opacity: 0.5"
    />
  </div>
</template>

<script>
import { toMoneyLabel } from "@/shared/helpers/numbersManipulation";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import BarShare from "@/modules/main/components/barShare";
import { ref } from "vue";

const UseAssents = createNamespacedHelpers("assents");

export default {
  name: "ProductItemStore",

  components: { BarShare },

  props: {
    product: {
      type: Object,
    },
    skeleton: {
      type: Boolean,
    },
  },

  setup(props) {
    let imageSt = 1;
    const prodImage = ref(props.product ? props.product.path : null),
      { ImgNotFound } = UseAssents.useGetters(["ImgNotFound"]),
      onErrorImage = () => {
        if (imageSt === 1) {
          prodImage.value = props.product.origin_link;
          imageSt++;
        } else {
          prodImage.value = ImgNotFound;
        }
      };

    return {
      prodImage,
      onErrorImage,
      toMoneyLabel,
    };
  },
};
</script>
