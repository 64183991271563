<template>
  <div class="q-mb-md q-mt-md row q-col-gutter-md items-center flex-container">
    <div class="col-12 col-md-2">
      <q-select
        outlined
        clearable
        color="black"
        class="bg-white"
        v-model="modelPeriod"
        :options="options"
        :disable="disabled"
        :label="$t('global.period')"
      >
        <template v-slot:option="scope">
          <q-item
            v-bind="scope.itemProps"
            @click="clickSelectOption(scope.opt)"
          >
            {{ scope.opt.label }}
          </q-item>
        </template>
      </q-select>
    </div>
    <div class="col-12 col-md-10" style="align-self: flex-end">
      <q-badge
        color="dark"
        class="q-pl-md q-py-sm q-pr-md flip-border-radius-24"
        v-if="periodData"
      >
        {{ getLabel(periodData) }}
      </q-badge>
    </div>
  </div>
  <div
    class="row q-col-gutter-none justify-between bg-white widget-containers"
    :class="progress ? 'flipnet-opacity' : ''"
  >
    <q-linear-progress color="dark" indeterminate v-if="progress" />
    <div
      class="column items-center relative-position"
      v-for="(v, n) in widgets"
      :key="`none-${n}`"
      :class="{
        'col-md-6': widgets.length == 2 && $q.screen.width > 900,
        'col-md-4': widgets.length == 3 && $q.screen.width > 900,
        'col-md-3': widgets.length == 4 && $q.screen.width > 900,
        'col-md-2': widgets.length == 5 && $q.screen.width > 900,
        wc: $q.screen.width <= 900,
      }"
      :style="{
        borderLeft:
          n != 0 && $q.screen.width > 900 ? `1px solid #ecedee` : 'none',
      }"
    >
      <widget
        :length="widgets.length"
        :button_title="v.button_title"
        :skeleton="v.on_request"
        :details="v.details"
        :type="v.type"
        :value="v.value"
        :reward_points_expired_soon="v.reward_points_expired_soon"
        :credits_under_analysis="v.credits_under_analysis"
        :total_orders="v.total_orders"
        :feeling="v.feeling"
        :series="v.series"
        :title="$t(v.title)"
        :link="v.link"
        :identifier="v.identifier"
        :classes="v.classes"
        :icon="v.icon"
        :sub_label="v.sub_label || null"
        :tooltip_message="v.tooltip_message"
        :button_icon="v.button_icon"
      />
    </div>
  </div>
</template>

<script setup>
import {
  formatPeriod,
  formatPeriodByMonths,
  formatDate,
} from "@/shared/helpers/numbersManipulation";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import Widget from "@/modules/main/pages/home/components/widget";
import { useI18n } from "vue-i18n";
import { onMounted, reactive, ref, watch } from "vue";
import { useQuasar } from "quasar";
import Calendar from "./calendar.vue";

const { useGetters, useActions } = createNamespacedHelpers("home");

const { widgets } = useGetters({
  widgets: "getWidgets",
});

const { fetchWidgetsServices } = useActions(["fetchWidgetsServices"]);

const { t } = useI18n();
const $q = useQuasar();

const periodData = ref(null);
const modelPeriod = ref(null);
const previousDate = ref("");
const progress = ref(false);

const options = reactive([
  {
    label: t("sales.today"),
    value: 0,
  },
  {
    label: t("sales.yesterday"),
    value: 1,
  },
  {
    label: t("Mês atual"),
    value: "current_month",
  },
  {
    label: t("Mês passado"),
    value: "last_month",
  },
  ...[7, 15, 30].map((n) => ({
    label: t("sales.lasted_n_days", { n }),
    value: n,
  })),
  {
    label: "Personalizado",
    value: "period_custom",
  },
]);

const clickSelectOption = (optionSelected) => {
  if (optionSelected.value === "period_custom") {
    return $q.dialog({
      component: Calendar,
      componentProps: {
        listeningCustomDate: (newPeriod) => {
          if (newPeriod === null) {
            if (!previousDate?.value) modelPeriod.value = null;
            else
              modelPeriod.value = options.find(
                (option) => option.value === previousDate.value
              );

            return;
          }

          progress.value = true;
          fetchWidgetsServices(getPeriods(newPeriod)).finally(() => {
            progress.value = false;
          });

          periodData.value = newPeriod;
        },
      },
    });
  }
};

const getLabel = (period) => {
  if (period) {
    const hoje = new Date();
    const ano = hoje.getFullYear().toString().slice(-2);
    const mes = `${hoje.getMonth() + 1}`.padStart(2, "0");
    const dia = `${hoje.getDate()}`.padStart(2, "0");
    const dataFormatada = `${ano}-${mes}-${dia}`;

    const equalDates = period.created_at_end == period.created_at_begin;

    return equalDates && period.created_at_end == dataFormatada
      ? t("sales.today")
      : equalDates && period.created_at_end != dataFormatada
      ? `Data: ${formatDate(period.created_at_begin)}`
      : `De ${formatDate(period.created_at_begin)} até ${formatDate(
          period.created_at_end
        )}`;
  }
};

const getPeriods = (formattedPeriod) => {
  if (formattedPeriod == null) return {};

  const getServices = {
    start_date: formattedPeriod.created_at_begin,
    end_date: formattedPeriod.created_at_end,
  };

  const postServices = {
    begin_at: formattedPeriod.created_at_begin,
    end_at: formattedPeriod.created_at_end,
  };

  return { getServices, postServices };
};

watch(modelPeriod, (newValue, oldValue) => {
  if (newValue && newValue.value === "period_custom") {
    previousDate.value = oldValue ? oldValue.value : null;

    return;
  }

  periodData.value =
    newValue != null
      ? newValue.value == "current_month" || newValue.value == "last_month"
        ? formatPeriodByMonths(newValue.value)
        : formatPeriod(newValue.value)
      : null;

  progress.value = true;

  fetchWidgetsServices(getPeriods(periodData.value)).finally(() => {
    progress.value = false;
  });
});

onMounted(() => fetchWidgetsServices());
</script>
<style lang="scss">
.widget-containers .wc {
  max-width: 300px;
  width: 100%;
}

.widget-containers .wc .widget-separator {
  width: 1px;
  background-color: #ecedee;
  height: 80%;
  display: flex;
  position: absolute;
  top: 10%;
  left: 0;
}

.widget-containers {
  .wc {
    max-width: 300px;
    width: 100%;
  }
}
</style>

<style lang="scss" scoped>
.wc:not(:first-child) .widget-separator {
  width: 1px;
  background-color: #ecedee;
  height: 80%;
  display: flex;
  position: absolute;
  top: 10%;
  left: 0;
}

.widget-containers {
  .wc {
    max-width: 300px;
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .widget-containers {
    .wc {
      max-width: 300px;
      width: 50%;
    }
  }
}
</style>
<style>
.flex-container .q-field__native span {
  font-size: 12px !important;
}
</style>
