<template>
  <q-card flat class="default-rounded">
    <q-card-section class="text-center">
      <q-badge outline rounded color="green" class="q-pa-sm">
        {{ data.partners[0].approved ? "Aprovado" : "Em análise" }}
      </q-badge>
    </q-card-section>
    <div class="q-pr-md q-pl-md text-center">
      <img :src="data.logo_foto_link" class="q-mt-md" style="height: 4.5em" />
      <q-item-label lines="3" class="text-justify q-mt-lg q-mb-lg">{{
        data.biography
          ? data.biography
          : $t("dashboard.flip.cards.no_biography", { company: data.nome })
      }}</q-item-label>
    </div>

    <q-card-actions align="right">
      <q-btn
        dense
        flat
        color="black"
        icon="open_in_new"
        @click="redirect(data.slug)"
      >
        <q-tooltip>
          {{ $t("dashboard.flip.cards.access") }}
        </q-tooltip>
      </q-btn>
    </q-card-actions>
  </q-card>
</template>

<script setup>
import { defineProps } from "vue";

defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const redirect = (slug) => window.open(`/${slug}/login`, "_blank");
</script>
