<template>
  <q-page-sticky
    :key="i"
    color="black"
    position="right"
    class="page-sticky"
    :offset="[18, 18 + i * 100]"
    @click="redirect(sn.link)"
    v-for="(sn, i) in formatGroup(links)"
  >
    <q-btn :icon="sn.icon" round color="black" @click="handleAction(sn)">
      <q-tooltip class="bg-black">
        {{ sn.message }}
      </q-tooltip>
    </q-btn>
  </q-page-sticky>

  <q-dialog v-model="messageDialog">
    <q-card>
      <q-card-section
        class="q-pt-none q-ma-sm q-pa-md default-rounded bg-grey-4"
      >
        <div v-html="selectedGroup.label" class="text-justify" />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          :icon="selectedGroup.icon"
          label="Acessar"
          class="full-width"
          color="black"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { useGetters } from "vuex-composition-helpers";
import { useQuasar } from "quasar";
import { useI18n } from "vue-i18n";
import { ref } from "vue";

const { links } = useGetters({
  links: "views/getCardLink",
});

const $q = useQuasar();
const { t } = useI18n();

const selectedGroup = ref(null);
const messageDialog = ref(false);

const formatGroup = (groups) => {
  let formatedNetworks = [];

  groups.forEach((group) => {
    formatedNetworks.push({
      icon: `fab fa-${group.icon.replace("_group", "")}`,
      link: group.value,
      message: `Grupo do ${group.icon.replace("_group", "")}`,
      label: t("new_dashboard.groups." + group.icon),
    });
  });

  const result = formatedNetworks.filter((e) => e.link);
  return result;
};

const handleAction = (group) => {
  if ($q.screen.width < 900) {
    selectedGroup.value = group;
    messageDialog.value = true;
  } else {
    if (group.link) window.open(group.link, "_blank");
  }
};
</script>

<style lang="scss" scoped>
.page-sticky {
  margin-top: -100px !important;
  opacity: 0.5;
}
.page-sticky:hover {
  opacity: 1;
  transition: 0.5s;
}
</style>
