<template>
  <div
    v-if="in_request"
    class="full-width full-height bg-grey-3 relative-position"
  >
    <q-skeleton
      class="absolute-top"
      width="100%"
      height="100%"
      animation="blink"
    />
    <q-skeleton type="text" width="150px" />
    <div class=" justify-center q-mb-lg q-mt-lg full-width row items-center">
      <q-skeleton
        width="80%"
        height="30px"
        class="q-mt-lg"
        v-for="(s, i) in [1, 2, 3]"
        :key="i"
      />
    </div>
  </div>
  <div v-else class="card-share-now q-pa-md bg-white text-ds-grey ">
    <div class="title">{{ $t("dashboard.share_now_title") }}</div>
    <div v-if="spread" class="column text-center justify-center q-ma-lg">
      <q-btn
        unelevated
        align="between"
        color="pink-3"
        icon-right="ads_click"
        class="q-mt-lg btn-fixed-width"
        :label="$t('dashboard.campaigns_wpp')"
        v-if="hasLink(1)"
        @click="openLink(1)"
      />
      <q-btn
        unelevated
        color="pink-3"
        align="between"
        icon-right="ads_click"
        class="q-mt-lg btn-fixed-width"
        :label="$t('dashboard.campaigns_insta')"
        v-if="hasLink(2)"
        @click="openLink(2)"
      />
      <q-btn
        unelevated
        color="pink-3"
        align="between"
        icon-right="ads_click"
        class="q-mt-lg btn-fixed-width"
        :label="$t('dashboard.how_to_share')"
        v-if="hasLink(3)"
        @click="openLink(3)"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { onMounted, ref } from "vue";

const { useGetters, useActions } = createNamespacedHelpers("home");

export default {
  name: "ShareNow",

  setup() {
    const in_request = ref(true),
      { spread } = useGetters({
        spread: "getSpread",
      }),
      { fetchSpread } = useActions(["fetchSpread"]);

    const getLink = (type) => {
        let link = null;

        if (type === 1 && spread.value.whatsapp_drive_share_link)
          link = spread.value.whatsapp_drive_share_link.value;
        else if (type === 2 && spread.value.instagram_drive_share_link)
          link = spread.value.instagram_drive_share_link.value;
        else if (spread.value.drive_share_link)
          link = spread.value.drive_share_link.value;

        return link;
      },
      hasLink = (type) => getLink(type) !== null,
      openLink = (type) => {
        const link = getLink(type);
        if (link) window.open(link, "_blank").focus();
      };

    onMounted(() => fetchSpread().finally(() => (in_request.value = false)));

    return {
      spread,
      in_request,
      hasLink,
      openLink,
    };
  },
};
</script>

<style lang="scss" scoped>
.card-share-now .title {
  text-transform: uppercase;
  font-size: 15px;
}
.mobile {
  .card-share-now {
    button {
      font-size: 12px;
    }
  }
}
</style>
