<!--suppress ES6CheckImport -->
<template>
  <div class="rickshaw-chart" ref="rickshawRef"></div>
</template>

<script>
import { onMounted, ref } from "vue";
import { Graph } from "rickshaw";
import { uniqueId } from "lodash";

export default {
  props: {
    color: {
      type: String,
      default: "#1dcbff",
    },
    series: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const rickshawRef = ref(null);
    const graph = ref(null);
    const name_gradient = uniqueId("areaGradient");

    onMounted(async () => {
      graph.value = new Graph({
        element: rickshawRef.value,
        stack: true,
        width: 100,
        height: 20,
        renderer: "multi",
        interpolation: "linear",
        series: [
          {
            renderer: "line",
            interpolation: "basis",
            strokeWidth: "2",
            data: props.series,
            color: props.color,
          },
          {
            width: 40,
            height: 20,
            renderer: "area",
            stroke: !0,
            data: props.series,
            color: "url(#" + name_gradient + ")",
          },
        ],
      });

      await graph.value.render();
      let s = rickshawRef.value.querySelector("svg g:last-child");

      s.insertAdjacentHTML(
        "beforeend",
        "<defs>\n" +
          '   <linearGradient id="' +
          name_gradient +
          '" x1="0%" y1="0%" x2="0%" y2="100%">\n' +
          `      <stop offset="0%" stop-color="${props.color}" stop-opacity="1"></stop>\n` +
          '      <stop offset="100%" stop-color="white" stop-opacity="1"></stop>\n' +
          "   </linearGradient>\n" +
          "</defs>\n"
      );
    });

    return {
      rickshawRef,
    };
  },
};
</script>

<style lang="scss">
.widget-footer {
  font-size: 13px;
  text-align: center;
  padding: 15px 0 15px;
}
.rickshaw-chart svg {
  display: block;
}
</style>
