<template>
  <div class="q-pa-md q-gutter-sm">
    <q-dialog v-model="seamless" seamless position="bottom">
      <q-card style="width: 700px; max-width: 80vw;">
        <q-bar class="bg-black text-white">
          <q-space />
          <q-btn dense flat icon="close" v-close-popup>
            <q-tooltip class="bg-black text-white">{{
              $t("global.close")
            }}</q-tooltip>
          </q-btn>
        </q-bar>
        <q-card-section class="show-up-content" v-if="show_up">
          <span v-html="show_up.content" />
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat :label="$t('global.close')" color="black" v-close-popup />
          <q-btn
            flat
            color="red-7"
            v-close-popup
            @click="watched"
            :label="$t('global.no_show_me_again')"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { notifyError, notifyInfo } from "@/shared/helpers/notify";
import { ref } from "vue";

const { useActions, useGetters } = createNamespacedHelpers("home");

export default {
  name: "showUp",

  setup() {
    const { fetchShowUp, showUpWatched } = useActions([
      "fetchShowUp",
      "showUpWatched",
    ]);
    const seamless = ref(false),
      { show_up, is_watched } = useGetters({
        show_up: "getShowUp",
        is_watched: "isShowUpWatched",
      });

    fetchShowUp().then(() => {
      if (show_up.value && is_watched.value === false) seamless.value = true;
      else seamless.value = false;
    });

    const watched = () =>
      showUpWatched()
        .then(() => notifyInfo("notify.success"))
        .catch(() => notifyError("notify.unprocessed_error_on_created"));

    return {
      seamless,
      show_up,
      watched,
    };
  },
};
</script>

<style lang="scss">
.show-up-content {
  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
  }
  .video-container iframe,
  .video-container object,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
