<template>
  <div class="q-mt-md" v-if="getPublicHeader.length" style="position: relative">
    <template v-for="(item, i) in getPublicHeader" :key="i">
      <q-img
        :name="i + 1"
        :src="item.value"
        v-if="i + 1 == slide"
        @click="windowOpen(item.link_to_open)"
        style="width: 100%; object-fit: cover; cursor: pointer"
      />
    </template>
    <q-btn
      v-if="getPublicHeader.length > 1"
      @click="prevSlide"
      icon="arrow_left"
      class="slide-button left"
      round
      dense
    />
    <q-btn
      v-if="getPublicHeader.length > 1"
      @click="nextSlide"
      icon="arrow_right"
      class="slide-button right"
      round
      dense
    />
  </div>
</template>

<script setup>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { ref, onMounted } from "vue";
import { useQuasar } from "quasar";

const missionsNamespaced = createNamespacedHelpers("missions");

const $q = useQuasar(),
  { getPublicHeader } = missionsNamespaced.useGetters({
    getPublicHeader: "getPublicHeader",
  }),
  { fetchPublicHeader } = missionsNamespaced.useActions({
    fetchPublicHeader: "fetchPublicHeader",
  });

const slide = ref(1);

const windowOpen = (link) => {
  if (link) window.open(link, "_blank");
};

const prevSlide = () => {
  slide.value =
    slide.value > 1 ? slide.value - 1 : getPublicHeader.value.length;
};

const nextSlide = () => {
  slide.value =
    slide.value < getPublicHeader.value.length ? slide.value + 1 : 1;
};

onMounted(() => {
  fetchPublicHeader({
    target:
      $q.screen.width < 900
        ? "dashboard_slider_main_mobile"
        : "dashboard_slider_main_desktop",
  });
});
</script>

<style>
.slide-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}
.slide-button.left {
  left: 10px;
}
.slide-button.right {
  right: 10px;
}
</style>
