<template>
  <div class="q-mt-md" v-if="getBanner.length" style="position: relative">
    <template v-for="(item, i) in getBanner" :key="i">
      <q-img
        :name="i + 1"
        :src="item.value"
        v-if="i + 1 == slide"
        @click="windowOpen(item)"
        style="width: 100%; object-fit: cover; cursor: pointer"
      />
    </template>
    <q-btn
      v-if="getBanner.length > 1"
      @click="prevSlide"
      icon="arrow_left"
      class="slide-button left"
      round
      dense
    />
    <q-btn
      v-if="getBanner.length > 1"
      @click="nextSlide"
      icon="arrow_right"
      class="slide-button right"
      round
      dense
    />
  </div>
</template>

<script setup>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { ref, onMounted } from "vue";
import { useQuasar } from "quasar";

const homeNamespaced = createNamespacedHelpers("home"),
  authNamespaced = createNamespacedHelpers("auth");

const $q = useQuasar(),
  { userSlug } = authNamespaced.useGetters({
    userSlug: "userSlug",
  }),
  { fetchBanner } = homeNamespaced.useActions(["fetchBanner"]),
  { getBanner } = homeNamespaced.useGetters({
    getBanner: "getBanner",
  });

const slide = ref(1);

const windowOpen = (item) =>
  window.open(
    item.link_to_open.replace(/{slug_parceiro}/g, userSlug.value),
    item.link_to_open_target
  );

const prevSlide = () => {
  slide.value = slide.value > 1 ? slide.value - 1 : getBanner.value.length;
};

const nextSlide = () => {
  slide.value = slide.value < getBanner.value.length ? slide.value + 1 : 1;
};

onMounted(() => {
  fetchBanner({
    target: $q.platform.is.mobile
      ? "dashboard_slider_main_mobile"
      : "dashboard_slider_main_desktop",
  });
});
</script>

<style>
.slide-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}
.slide-button.left {
  left: 10px;
}
.slide-button.right {
  right: 10px;
}
</style>
