<template>
  <div
    v-if="in_request"
    class="
      full-width full-height
      bg-grey-3
      relative-position
      column
      justify-between
    "
  >
    <q-skeleton
      class="absolute-top"
      width="100%"
      height="100%"
      animation="blink"
    />
    <q-skeleton type="text" width="150px" />
    <div class="justify-center full-width row items-center">
      <q-skeleton type="QAvatar" size="10em" />
    </div>
    <div class="row justify-around no-padding">
      <q-skeleton width="100%" height="40px" />
    </div>
  </div>
  <div v-else class="bg-white">
    <DashAreaComponent
      class="q-mb-lg"
      :title="$t('dashboard.invite_title', { value: maxValue })"
      :title-class="{ 'title-small': true }"
      :tooltipText="bonusInviteHelp"
      :floating="false"
      :titleBorder="false"
    >
      <div class="full-width row text-center justify-center q-ma-sm">
        <q-knob
          show-value
          font-size="16px"
          class="q-mb-lg q-mt-lg"
          readonly
          v-model="percent"
          size="180px"
          :thickness="0.03"
          color="black"
          track-color="grey-6"
        >
          <q-item-label style="font-size: 25px">
            {{ valueBase }} / {{ maxValue }}
          </q-item-label>
        </q-knob>
      </div>
      <bar-share
        color="#444444"
        :title="message"
        class="full-width q-mt-md"
        :link="signUpRouteOptional ? signUpRouteOptional : signUpRoute.url"
      />
    </DashAreaComponent>
  </div>
</template>

<script>
import {
  createNamespacedHelpers,
  useGetters,
  useActions,
} from "vuex-composition-helpers";
import BarShare from "@/modules/main/components/barShare";
import DashAreaComponent from "./DashAreaComponent.vue";
import { baseURL } from "@/shared/http/api";
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import axios from "axios";

const namespacedHelpers = createNamespacedHelpers("home"),
  namespacedPartner = createNamespacedHelpers("partner");

export default {
  components: { BarShare, DashAreaComponent },

  setup() {
    const router = useRouter(),
      in_request = ref(true),
      signUpRouteOptional = ref("");

    const valueBase = ref(0),
      maxValue = ref(0),
      message = ref(""),
      percent = ref(0),
      { invites } = namespacedHelpers.useGetters({
        invites: "getInvites",
      }),
      { partner_slug, user_slug } = useGetters({
        partner_slug: "partner/getPartnerSlug",
        user_slug: "auth/userSlug",
      }),
      { bonusInviteHelp } = namespacedPartner.useGetters({
        bonusInviteHelp: "getPartnerBonusInviteHelp",
      });

    const fetchParam = (param) =>
      axios.get(
        `${baseURL}/api/v1/parameters/p/${partner_slug.value}/${param}`
      );

    const { fetchInvites } = useActions({
      fetchInvites: "home/fetchInvites",
    });

    const signUpRoute = router.resolve({
      name: "sign.up",
      params: {
        slug: partner_slug.value,
      },
      query: {
        indicated_by: user_slug.value,
      },
    });

    signUpRoute.url = window.location.origin + signUpRoute.href;

    fetchInvites()
      .then(() => {
        if (invites.value) {
          valueBase.value = invites.value[0].invitesAccepted;
          maxValue.value = invites.value[1].total;
          message.value = invites.value[2].message;
          percent.value = ((valueBase.value / maxValue.value) * 100).toFixed(2);
        }
      })
      .finally(() => (in_request.value = false));

    onMounted(() => {
      fetchParam("refirect_signup").then(
        (res) =>
          (signUpRouteOptional.value =
            res.data.value == "application"
              ? ""
              : res.data.value + `?indicated_by=${user_slug.value}`)
      );
    });

    return {
      percent,
      message,
      maxValue,
      valueBase,
      in_request,
      signUpRoute,
      bonusInviteHelp,
      signUpRouteOptional,
    };
  },
};
</script>

<style scoped>
.card-invite .title {
  text-transform: uppercase;
  font-size: 15px;
}
</style>
