<template>
  <div
    class="ic-voucher row justify-end width-fit-content-flex"
    :class="{ skeleton: skeleton }"
  >
    <q-skeleton
      v-if="skeleton"
      class="absolute-top"
      width="100%"
      height="100%"
    />
    <div class="border-voucher width-fit-content-flex"></div>
    <div class="content">
      {{ getLabel(value, type) }}
    </div>
  </div>
</template>

<script>
import { toMoneyLabel } from "@/shared/helpers/numbersManipulation";

export default {
  name: "VoucherIcon",

  props: {
    value: {
      default: "0",
    },

    type: {
      default: "percent",
    },

    skeleton: {
      type: Boolean,
    },
  },

  setup() {
    const getLabel = (value, type) => {
      if (type == "percent") return `${value} %`;
      else if (type == "money") return toMoneyLabel(value);
    };

    return {
      getLabel,
    };
  },
};
</script>

<style scoped lang="scss">
.ic-voucher {
  padding: 10px;
  border: #5d5d5d solid;
  height: 100px;
  max-width: 200px;
  position: relative;
  .content {
    border-left: 2px #5d5d5d dashed;
    height: 100%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    color: #5d5d5d;
  }
  .border-voucher {
    background-color: white;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    border-right: #5d5d5d solid;
    position: absolute;
    left: -30px;
    top: 24%;
  }
}
.ic-voucher.skeleton {
  border: transparent solid;
  .content {
    border-left: 2px grey dashed;
    color: grey;
  }
  .border-voucher {
    background-color: transparent;
    border-right: grey solid;
  }
}
</style>
