<template>
  <div
    v-if="voucher"
    class="card-voucher full-height q-pa-md bg-white text-ds-grey"
  >
    <div v-if="!voucher" class="title">{{ $t("dashboard.voucher_title") }}</div>
    <page-title
      :floating="false"
      :title-class="{ 'title-small': true }"
      :tooltip="partnerVoucherHelp"
      :title="$t('dashboard.voucher_title')"
      class="q-mb-lg"
    >
    </page-title>
    <div class="row text-center justify-center q-ma-lg">
      <p class="full-width q-ma-md">{{ voucher.description }}</p>
      <voucher-icon :value="voucher.discount" :type="voucher.type_discount" />
      <p class="q-ma-lg full-width">{{ voucher.number }}</p>
    </div>
    <bar-share
      :link="getLink(link_store)"
      :title="voucher.number"
      :description="voucher.description"
      :hide-only-link-copy="true"
      color="#79899d"
    />
  </div>
</template>

<script setup>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import VoucherIcon from "@/modules/main/components/voucherIcon";
import PageTitle from "@/modules/main/components/page-title";
import BarShare from "@/modules/main/components/barShare";

const { useGetters } = createNamespacedHelpers("home");
const namespacedPartner = createNamespacedHelpers("partner");
const partnerStore = createNamespacedHelpers("partner");

const { voucher } = useGetters({
    voucher: "getVoucher",
  }),
  { link_store } = partnerStore.useGetters({
    link_store: "getStoreLink",
  }),
  { partnerVoucherHelp } = namespacedPartner.useGetters({
    partnerVoucherHelp: "getPartnerVoucherHelp",
  });

const getLink = (link) => (typeof link === "string" ? link : link.content);
</script>

<style scoped>
.card-voucher .title {
  text-transform: uppercase;
  font-size: 15px;
}
</style>
