<template>
  <h1
    class="title text-h5 default-rounded"
    :class="{
      skeleton: skeleton,
      'q-pa-sm bg-white default-shadow': floating,
      ...titleClass,
    }"
  >
    <span class="row justify-between">
      <span class="title-content row items-center">
        <span class="text-uppercase text-grey-8">
          {{ $t(title) }}
        </span>

        <span class="q-ml-xs">
          <q-btn
            :ripple="false"
            flat
            round
            v-if="video_url"
            @click="onTooltipClick"
            icon="helper"
            color="dark"
          >
            <show-up-video :clicked-count="clicked_count" :url="video_url" />
          </q-btn>
          <tooltip v-else-if="tooltip" :tooltip_message="tooltip" />
        </span>
      </span>
      <slot></slot>
    </span>
  </h1>
</template>

<script>
import ShowUpVideo from "@/modules/main/components/showUpVideo";
import { ref } from "vue";
import tooltip from "./tooltip.vue";

export default {
  name: "SideTitleComponent",

  components: { ShowUpVideo, tooltip },

  props: {
    titleClass: {
      type: Object,
      required: true,
    },
    floating: {
      type: Boolean,
      default: true,
    },
    tooltip: {
      type: String,
    },
    skeleton: {
      type: Boolean,
      default: false,
    },
    video_url: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const clicked_count = ref(0),
      onTooltipClick = () => {
        if (props.video_url && props.video_url.length) {
          clicked_count.value++;
        }
      };

    return {
      clicked_count,
      onTooltipClick,
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #000000;
  margin-top: 0;
  font-size: 1.2em;
}
.title::after {
  content: "";
  display: block;
  max-width: 60px;
  height: 2px;
  width: 100%;
  background: #3e4956;
}

.title.skeleton .title-content {
  color: rgb(212, 211, 222) !important;
}
.title.skeleton::after {
  background: rgb(212, 211, 222);
}

.title-small {
  color: #79899d;
  font-size: 15px;
  text-transform: uppercase;
  &:after {
    content: none;
  }
}
.mobile {
  .title {
    font-size: 15px;
  }
}
</style>
