<template>
  <div
    class="card-invite justify-between bg-white"
    :class="{
      'card-border': isFlipnetCompany,
    }"
  >
    <div class="q-pa-md">
      <SideTitleComponent
        :title="title"
        :class="{ 'title-small': !titleBorder }"
        :tooltip="tooltipText"
        :floating="false"
      />
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import SideTitleComponent from "@/modules/main/components/SideTitleComponent";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { defineProps } from "vue";

const partnerNamespace = createNamespacedHelpers("partner");

defineProps({
  title: {
    type: String,
    required: true,
  },
  titleBorder: {
    type: Boolean,
    default: true,
  },
  tooltipText: {
    type: String,
    default: "",
  },
});

const { isFlipnetCompany } = partnerNamespace.useGetters({
  isFlipnetCompany: "isFlipnetCompany",
});
</script>

<style scoped>
.card-border {
  border-top: 5px solid grey;
}
.card-invite .title-small {
  text-transform: uppercase;
  font-size: 15px;
}
</style>
