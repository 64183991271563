<template>
  <q-card flat class="default-rounded card-border" style="min-height: 300px;">
    <q-card-section class="row">
      <q-space />
      <q-skeleton type="QBtn" />
    </q-card-section>
    <div class="q-pr-md q-pl-md text-center">
      <q-skeleton
        type="circle"
        size="100px"
        class="q-mt-md q-mr-auto q-ml-auto"
      />
      <q-item-label lines="3" class="text-justify q-mt-lg q-mb-sm">
        <q-skeleton type="text" />
        <q-skeleton type="text" />
        <q-skeleton type="text" />
      </q-item-label>
    </div>
  </q-card>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CardFlipPartnerSubscribeSkeleton",
});
</script>

<style lang="scss" scoped>
.card-border {
  border: 1px solid #5a5a5a;
}
</style>
