<template>
  <div
    class="card-voucher full-height q-pa-md bg-white"
    :class="{
      'border-top-flip': partner.slug == 'flipnet',
    }"
  >
    <div class="row">
      <div class="col-12 col-md-7 q-pt-none">
        <page-title
          :floating="false"
          :title-class="{ 'title-small': true }"
          :tooltip="$t('dashboard.tooltip_text')"
          :title="$t('dashboard.visitors')"
          class="q-mb-md margin-top-flip"
        />
      </div>
      <div class="col-12 col-md-5">
        <q-select
          :options="options"
          dense
          class="full-width"
          color="dark"
          :label="$t('global.period')"
          v-model="model"
        />
      </div>
    </div>
    <q-card class="q-mt-md" flat>
      <q-card-section horizontal>
        <img src="/dash-icons/visitors-icon.png" class="image q-ml-md" />
        <q-card-section class="counter">
          <vue3-autocounter
            class="text-counter-size text-grey-8"
            ref="counter"
            :startAmount="0"
            :endAmount="visitors.value"
            :duration="1"
            separator="."
            :autoinit="true"
          />
          <div>
            <q-icon
              :name="
                visitors.up_down_value == true ? 'expand_less' : 'expand_more'
              "
              :color="visitors.up_down_value == true ? 'green' : 'red'"
            />

            <span
              style="color: green"
              :class="
                visitors.up_down_value == true ? 'text-green' : 'text-red'
              "
            >
              ({{ parseFloat(visitors.percent).toFixed(2) }}% )</span
            >
          </div>
        </q-card-section>
      </q-card-section>

      <vue3-highcharts
        type="chart"
        @update="onUpdate"
        @rendered="onRender"
        @destroy="onDestroy"
        :options="chartData"
        :redrawOnUpdate="true"
        :oneToOneUpdate="false"
        :animateOnUpdate="true"
      />
    </q-card>
    <!--    
    <div class="text-grey-7" style="font-size: 8pt">
      {{ $t("dashboard.explaining_text") }}
    </div>
  -->
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import PageTitle from "@/modules/main/components/page-title";
import { reactive, ref, onMounted, watch, computed } from "vue";
import Vue3autocounter from "vue3-autocounter";
import VueHighcharts from "vue3-highcharts";
import i18n from "@/i18n/wrappers/i18n";
import moment from "moment";

const t = i18n.global.t;
const { useGetters, useActions } = createNamespacedHelpers("home");
const partnerNamespace = createNamespacedHelpers("partner");

export default {
  name: "visitorsBoxComponent",

  components: {
    PageTitle,
    "vue3-autocounter": Vue3autocounter,
    "vue3-highcharts": VueHighcharts,
  },

  setup() {
    const model = ref({
        label: t("sales.today"),
        value: 0,
      }),
      old = ref(0),
      getToday = () => moment().format("YYYY-MM-DD"),
      getPeriodDate = (num) => {
        let d = new Date();
        return moment(d.setDate(d.getDate() - num)).format("YYYY-MM-DD");
      },
      options = reactive([
        {
          label: t("sales.today"),
          value: 0,
        },
        {
          label: t("sales.yesterday"),
          value: 1,
        },
        ...[7, 30, 90].map((n) => ({
          label: t("sales.lasted_n_days", { n }),
          value: n == 90 ? 89 : n,
        })),
      ]),
      { fetchVisitors } = useActions(["fetchVisitors"]),
      { visitors } = useGetters({
        visitors: "getVisitorsData",
      });

    const { partner } = partnerNamespace.useGetters({
      partner: "getPartner",
    });

    onMounted(() => {
      fetchVisitors({
        range: {
          begin: getToday(),
          end: getToday(),
        },
      });
    });

    const chartData = computed(() => {
      return {
        chart: {
          backgroundColor: "white",
          type: "spline",
          height: 120,
        },
        title: {
          text: null,
        },
        yAxis: {
          labels: {
            enabled: false,
          },
          title: {
            text: null,
          },
          gridLineColor: "transparent",
        },
        xAxis: {
          labels: {
            enabled: false,
          },
          visible: false,
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false,
              symbol: "circle",
              radius: 0,
            },
          },
          spline: {
            color: visitors.value.up_down_color,
            dataLabels: {
              enabled: true,
              formatter: function() {
                return this.y > 0 ? parseInt(this.y) : "";
              },
            },
            enableMouseTracking: true,
          },
          areaspline: {
            fillOpacity: 0.5,
          },
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: "500px",
                maxHeight: "100px",
              },
            },
          ],
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "",
            data: visitors.value.series,
          },
        ],
      };
    });

    watch(model, async (currentValue) => {
      const todayOrYesterday =
        currentValue.value == 1
          ? getPeriodDate(currentValue.value)
          : getToday();

      await fetchVisitors({
        range: {
          end: todayOrYesterday,
          begin: getPeriodDate(currentValue.value),
        },
      });
    });

    return {
      options,
      model,
      visitors,
      old,
      chartData,
      partner,
    };
  },
};
</script>

<style scoped lang="scss">
.image {
  width: 100px;
  height: 100px;
  margin-left: 30px;
  display: block;
}
.text-counter-size {
  font-size: 25pt;
}
.counter {
  margin-left: 30px;
}
.mobile {
  .image {
    margin-top: 20px;
    margin-left: 20px;
    width: 110px;
    height: 110px;
  }
  .value {
    font-size: 20pt;
  }
}

//TODO: arrumar essas classes quando o dashboard for unico
.margin-top-flip {
  margin-top: -20px;
}

.border-top-flip {
  border-top: 5px solid grey;
}
</style>
