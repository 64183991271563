<template>
  <q-btn
    dense
    label="detalhes"
    color="black"
    class="q-pa-sm default-rounded btn-details"
    @click="modal = true"
  />
  <q-dialog
    v-if="modal"
    v-model="modal"
    persistent
    style="min-width: 700px !important"
  >
    <q-card>
      <q-bar class="bg-black">
        <q-space />
        <q-btn dense flat color="white" icon="close" v-close-popup>
          <q-tooltip>{{ $t("global.close") }}</q-tooltip>
        </q-btn>
      </q-bar>
      <q-card-section>
        <div
          v-if="verifyIfIsSubscribed(data.partner.slug)"
          class="text-justify bg-grey-3 q-mt-md q-mb-md q-pa-md"
          v-html="$t('dashboard.redirect_alert')"
        />
        <div v-else class="text-justify bg-grey-3 q-mt-md q-mb-md q-pa-md">
          <div
            class="text-center q-mb-sm"
            style="font-size: 2.5em"
            :class="{
              'text-red': verifyIfIsSubscribed(data.partner.slug) == false,
              'text-blue': verifyIfIsSubscribed(data.partner.slug) == null,
            }"
          >
            <q-icon
              :name="
                verifyIfIsSubscribed(data.partner.slug) == false
                  ? 'warning'
                  : 'pending'
              "
            />
          </div>
          <span
            v-html="
              $t(
                `dashboard.${
                  verifyIfIsSubscribed(data.partner.slug) == false
                    ? 'cant_subs_alert'
                    : 'subs_progress_alert'
                }`,
                { programa: data.partner.nome }
              )
            "
          />
        </div>
      </q-card-section>
      <q-card-actions align="right">
        <template v-if="verifyIfIsSubscribed(data.partner.slug)">
          <q-btn
            flat
            color="black"
            v-close-popup
            :label="$t('global.cancel')"
          />
          <q-btn
            color="black"
            :loading="in_request"
            :label="$t('global.continue')"
            @click="login(data.partner.slug, data.slug)"
          />
        </template>
        <template v-else>
          <q-btn flat color="black" v-close-popup :label="$t('global.close')" />
        </template>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { createNamespacedHelpers, useGetters } from "vuex-composition-helpers";
import { defineProps, ref } from "vue";

const authNamespace = createNamespacedHelpers("auth");

defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const modal = ref(false),
  in_request = ref(false);

const { getCompaniesRegisteredList } = useGetters({
  getCompaniesRegisteredList: "home/getCompaniesRegisteredList",
});

const { signInByDashboard } = authNamespace.useActions(["signInByDashboard"]);

const login = (slug, missionSlug) => {
  signInByDashboard({
    slug,
    path: `/${slug}/redirect/mission#${missionSlug}`,
  });
};

const verifyIfIsSubscribed = (slug) => {
  const subs = getCompaniesRegisteredList.value.filter((el) => el.slug == slug);

  if (subs.length) {
    return subs.pop().partners.pop().approved || null;
  } else return false;
};
</script>

<style lang="scss">
@media screen and (max-width: 800px) {
  .btn-details {
    font-size: 8pt !important;
  }
}
</style>
