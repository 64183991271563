<template>
  <q-card flat class="default-rounded full-height card-border">
    <q-item class="q-mt-sm">
      <q-item-section avatar>
        <q-skeleton
          type="circle"
          size="40px"
          class="q-mt-sm q-mr-auto q-ml-auto"
        />
      </q-item-section>
      <q-item-section>
        <q-item-label class="text-black text-uppercase">
          <q-skeleton type="text" />
        </q-item-label>
        <q-item-label caption lines="3" class="text-justify text-grey-8">
          <q-skeleton type="text" />
          <q-skeleton type="text" />
          <q-skeleton type="text" />
        </q-item-label>
      </q-item-section>
    </q-item>
    <q-skeleton height="250px" class="q-ma-md" />
    <q-card-section class="row">
      <q-space />
      <q-skeleton type="QBtn" />
    </q-card-section>
  </q-card>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CardFlipPartnerMissionSkeleton",

  props: {
    data: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.card-border {
  border: 1px solid #5a5a5a;
}
.date_label {
  font-size: 7pt !important;
}

.imgHeight {
  height: 270px;
}

@media screen and (max-width: 1600px) {
  .imgHeight {
    height: 210px;
  }
}

@media screen and (max-width: 1440px) {
  .imgHeight {
    height: 185px;
  }
}

@media screen and (max-width: 1280px) {
  .imgHeight {
    height: 150px;
  }
}

@media screen and (max-width: 800px) {
  .imgHeight {
    height: 140px;
  }
}
</style>
