<template>
  <DashAreaComponent title="Meus links" :titleBorder="false">
    <q-scroll-area style="height: 315px">
      <q-list bordered v-if="allLinks.length > 0">
        <q-item v-for="(link, i) in allLinks" :key="i">
          <q-item-section
            avatar
            style="cursor: pointer"
            @click="accessLink(link.link_store)"
          >
            <q-icon name="language" color="blue" />
          </q-item-section>

          <q-item-section
            style="margin-left: -20px; cursor: pointer"
            @click="accessLink(link.link_store)"
          >
            <q-item-label lines="1" class="text-blue">{{
              link.parent_name
            }}</q-item-label>
          </q-item-section>

          <q-item-section side>
            <q-icon
              name="content_copy"
              color="black"
              class="share-icon copy-btn"
              @click="() => copyLink(link.link_store)"
            />
          </q-item-section>
          <q-item-section side>
            <q-icon
              name="link"
              color="black"
              class="share-icon"
              @click="accessLink(link.link_store)"
            />
          </q-item-section>
          <q-item-section side>
            <ShareComponent :valueToShare="link.link_store" />
          </q-item-section>
        </q-item>
      </q-list>
      <div
        class="row text-center justify-center q-ma-lg q-mt-xl"
        v-if="allLinks.length == 0"
      >
        <q-icon name="shopping_bag" style="font-size: 120px" color="black" />
        <p class="q-ma-sm full-width link-code">Não há links disponíveis.</p>
      </div>
    </q-scroll-area>
  </DashAreaComponent>
</template>

<script>
// import { copyLink } from "../../../../../shared/helpers/checkoutHelpers";
import { notifySuccess, notifyError } from "@/shared/helpers/notify";
import DashAreaComponent from "./DashAreaComponent.vue";
import ShareComponent from "./ShareComponent.vue";

export default {
  name: "MyLinksComponent",

  components: { DashAreaComponent, ShareComponent },

  props: {
    allLinks: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const accessLink = (link) => window.open(link, "_blank");

    function copyLink(link) {
      if (!link) {
        console.error("Nenhum link fornecido para cópia.");
        notifyError("Nenhum link fornecido para cópia.");
        return;
      }

      // Usando a API Clipboard para copiar o texto
      navigator.clipboard
        .writeText(link)
        .then(() => {
          notifySuccess("Link copiado com sucesso!");
        })
        .catch((err) => {
          console.error("Erro ao copiar o link:", err);
          notifyError("Erro ao copiar o link.");
        });
    }

    return { accessLink, copyLink };
  },
};
</script>

<style scoped>
.share-icon {
  cursor: pointer;
}
.link-txt {
  color: black;
}
.link-code {
  font-size: 1.2em;
  color: #5d5d5d;
}
</style>
