<template>
  <q-icon
    name="share"
    color="black"
    class="share-icon"
    @click="modal = true"
    v-if="shareType == 'icon'"
  />
  <q-btn
    v-else
    icon="share"
    color="black"
    class="text-white"
    label="Compartilhar"
    @click="modal = true"
  />

  <q-dialog v-model="modal" transition-show="scale" transition-hide="scale">
    <q-card style="width: 350px" class="modal">
      <q-card-section>
        <q-card-title class="text-h6 text-uppercase text-grey-8"
          >Compartilhar</q-card-title
        >
        <div class="row q-col-gutter-sm q-mt-sm">
          <div
            class="col-12 col-md-6"
            v-for="option in shareOptions"
            :key="option.name"
            @click="option.action"
          >
            <q-card bordered flat clickable class="share-item">
              <q-card-section class="text-center itens-center">
                <q-icon :name="option.icon" style="font-size: 20pt;" />
              </q-card-section>
            </q-card>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { shareToSocialMedia } from "@/shared/helpers/navigatorHelpers";
import { ref } from "vue";

export default {
  name: "ShareComponent",

  props: {
    valueToShare: {
      type: String,
      required: true,
    },
    // icon or button
    shareType: {
      type: String,
      default: "icon",
    },
  },

  setup(props) {
    const shareOptions = [
      {
        name: "Facebook",
        icon: "fab fa-facebook",
        action: () => shareToSocialMedia("facebook", props.valueToShare),
      },
      {
        name: "WhatsApp",
        icon: "fab fa-whatsapp",
        action: () => shareToSocialMedia("whatsapp", props.valueToShare),
      },
    ];

    return { shareOptions, modal: ref(false) };
  },
};
</script>

<style scoped>
.modal {
  border-top: 5px solid gray;
}
.share-icon {
  cursor: pointer;
}
.share-item {
  cursor: pointer;
}
.share-item:hover {
  background-color: #e5e5e5;
}
</style>
